import { Backdrop, CircularProgress, Fade, Paper } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: "absolute",
    minHeight: "100%",
    minWidth: "100%",
    zIndex: theme.zIndex.drawer + 11,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 11,
    color: grey.A700,
  },
  paper: {
    // width: "fit-content",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
  circle: {
    marginBottom: "-4px",
  },
}));
export default function Components() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(true);

  return (
    <React.Fragment>
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? "200ms" : "0ms",
        }}
        unmountOnExit
      >
        <Backdrop
          invisible={true}
          className={classes.backdrop}
          open={open}
          // onClick={handleClickLoading}
        >
          <Paper className={classes.paper}>
            <CircularProgress className={classes.circle} />
          </Paper>
        </Backdrop>
      </Fade>
    </React.Fragment>
  );
}
