import {
  disableLoading,
  enableLoading,
  setLikesVisible,
  setMatchingsVisible,
  setMessagingsVisible,
} from "src/contexts/layout/Reducer";
import { HandleHttpErrorResponse } from "src/functions/Error";
import { loginNotificationApi } from "src/functions/OpenAPI";
import {
  LoginNotificationType,
  LoginNotificationUpdateInput,
} from "src/openapi";

function UpdateLoginNotification(
  input: LoginNotificationUpdateInput,
  dispatchLayout: React.Dispatch<any>
) {
  loginNotificationApi()
    .loginNotificationUpdate(input)
    .then((res) => {})
    .catch((error) => {
      HandleHttpErrorResponse(error, dispatchLayout);
    });
}

function FetchLoginNotificationList(dispatchLayout: React.Dispatch<any>) {
  enableLoading(dispatchLayout, "FETCHING");
  loginNotificationApi()
    .loginNotificationList()
    .then((res) => {
      const { loginNotifications } = res.data;
      loginNotifications &&
        loginNotifications.map((notification) => {
          switch (notification.type) {
            case LoginNotificationType.Messagings:
              setMessagingsVisible(dispatchLayout, notification.enabled);
              break;
            case LoginNotificationType.Matchings:
              setMatchingsVisible(dispatchLayout, notification.enabled);
              break;
            case LoginNotificationType.Likes:
              setLikesVisible(dispatchLayout, notification.enabled);
              break;
          }
        });
    })
    .catch((error) => {
      HandleHttpErrorResponse(error, dispatchLayout);
    })
    .finally(() => {
      disableLoading(dispatchLayout);
    });
}

export { UpdateLoginNotification, FetchLoginNotificationList };
