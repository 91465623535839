import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Env from "src/consts/environment";

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingID: string,
      config: { page_path: string }
    ) => void;
  }
}

export const useTracking = (trackingIDs: string[]) => {
  const { listen } = useHistory();
  const location = useLocation();
  initializeGA(trackingIDs);
  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      if (trackingIDs.length === 0) {
        console.log(
          "Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`."
        );
        return;
      }
    });
    trackingIDs.map((trackingID) => {
      window.gtag("config", trackingID, {
        page_path: location.pathname,
      });
    });

    return unlisten;
  }, [listen, location]);
};

// idで検索できるように埋め込むscript用の名前を定義
const SCRIPT1_NAME = "gtag";
const SCRIPT2_NAME = "gtagScript";
function initializeGA(trackingIDs: string[]) {
  // load gtag.js:  https://developers.google.com/analytics/devguides/collection/gtagjs

  trackingIDs.map((trackingID) => {
    // scriptが既にある場合は一度削除
    const script1ID = `${SCRIPT1_NAME}-${trackingID}`;
    const script2ID = `${SCRIPT2_NAME}-${trackingID}`;
    document.getElementById(script2ID)?.remove();
    document.getElementById(script1ID)?.remove();
    let script1 = document.createElement("script");
    script1.id = script1ID;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=" + trackingID;
    script1.async = true;
    document.head.appendChild(script1);

    let script2 = document.createElement("script");
    script2.id = script2ID;
    // プレーンテキスト、グローバルな window.gtag 関数を定義するため
    script2.text = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${trackingID}');`;
    document.head.appendChild(script2);
  });
}
