// 環境変数取得
declare const MODE: string;
declare const BASE_PATH: string;
declare const SERVICE_URL: string;
// Algolia
declare const ALGOLIA_APIKEY: string;
declare const ALGOLIA_PROJECT: string;
// Firebase
declare const FIREBASE_PROJECT_ID: string;
declare const FIREBASE_APIKEY: string;
declare const FIREBASE_AUTHDOMAIN: string;
declare const FIREBASE_DATABASE_URL: string;
declare const FIREBASE_STORAGE_BUCKET: string;
declare const FIREBASE_MESSAGING_SENDER_ID: string;
declare const FIREBASE_APP_ID: string;
declare const FIREBASE_MEASUREMENT_ID: string;
declare const FIREBASE_IS_APPVERIFICATION_DISABLED_FOR_TESTING: boolean;
// google ananlytics
declare const GOOGLE_ANALITICS_TRACKING_ID: string;
declare const GOOGLE_ANALITICS_TRACKING_ID_ADS: string;
// cloud storage
declare const CLOUD_STORAGE_URL: string;
// Stripe
declare const STRIPE_KEY: string;
declare const STRIPE_PRODUCT_ID: string;
// Telecom Credit
declare const TELECOMCREDIT_CLIENT_IP: string;

// 環境変数を入れるインスタンス定義
interface Environment {
  MODE: string;
  BASE_PATH: string;
  SERVICE_URL: string;
  ALGOLIA_APIKEY: string;
  ALGOLIA_PROJECT: string;
  FIREBASE_PROJECT_ID: string;
  FIREBASE_APIKEY: string;
  FIREBASE_AUTHDOMAIN: string;
  FIREBASE_DATABASE_URL: string;
  FIREBASE_STORAGE_BUCKET: string;
  FIREBASE_MESSAGING_SENDER_ID: string;
  FIREBASE_APP_ID: string;
  FIREBASE_MEASUREMENT_ID: string;
  FIREBASE_IS_APPVERIFICATION_DISABLED_FOR_TESTING: boolean;
  GOOGLE_ANALITICS_TRACKING_ID: string;
  GOOGLE_ANALITICS_TRACKING_ID_ADS: string;
  CLOUD_STORAGE_URL: string;
  STRIPE_KEY: string;
  STRIPE_PRODUCT_ID: string;
  TELECOMCREDIT_CLIENT_IP: string;
}

// 環境変数を入れるインスタンス
const Env: Environment = {
  MODE: MODE,
  BASE_PATH: BASE_PATH,
  SERVICE_URL: SERVICE_URL,
  ALGOLIA_APIKEY: ALGOLIA_APIKEY,
  ALGOLIA_PROJECT: ALGOLIA_PROJECT,
  FIREBASE_PROJECT_ID: FIREBASE_PROJECT_ID,
  FIREBASE_APIKEY: FIREBASE_APIKEY,
  FIREBASE_AUTHDOMAIN: FIREBASE_AUTHDOMAIN,
  FIREBASE_DATABASE_URL: FIREBASE_DATABASE_URL,
  FIREBASE_STORAGE_BUCKET: FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: FIREBASE_MEASUREMENT_ID,
  FIREBASE_IS_APPVERIFICATION_DISABLED_FOR_TESTING:
    FIREBASE_IS_APPVERIFICATION_DISABLED_FOR_TESTING,

  GOOGLE_ANALITICS_TRACKING_ID: GOOGLE_ANALITICS_TRACKING_ID,
  GOOGLE_ANALITICS_TRACKING_ID_ADS: GOOGLE_ANALITICS_TRACKING_ID_ADS,

  CLOUD_STORAGE_URL: CLOUD_STORAGE_URL,
  STRIPE_KEY: STRIPE_KEY,
  STRIPE_PRODUCT_ID: STRIPE_PRODUCT_ID,
  TELECOMCREDIT_CLIENT_IP: TELECOMCREDIT_CLIENT_IP,
};

export default Env;
