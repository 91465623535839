import { NotificationType, Notification } from "src/openapi";

export function isNotificationDisabled(
  notificationsMap: Map<NotificationType, Notification>,
  type: NotificationType
): boolean {
  if (!notificationsMap.has(type)) {
    return true;
  }
  return !notificationsMap.get(type).enabled;
}
