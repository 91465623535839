import React from "react";
import { LayoutContextProvider } from "src/contexts/layout/LayoutContext";
import { AuthContextProvider } from "src/contexts/AuthContext";
import { HeaderContextProvider } from "src/contexts/header/HeaderContext";
import { DebugUsersListContextProvider } from "src/contexts/debug/UsersListContext";
import { PartsContextProvider } from "src/contexts/parts/usersList/ContextProvider";

// ラップされた子要素全てが、children に props に入ってくる
export const ContextProvider = ({ children }) => {
  return (
    <LayoutContextProvider>
      <AuthContextProvider>
        <HeaderContextProvider>
          <PartsContextProvider>
            <DebugContextProvider>
              {/*  */}
              {children}
              {/*  */}
            </DebugContextProvider>
          </PartsContextProvider>
        </HeaderContextProvider>
      </AuthContextProvider>
    </LayoutContextProvider>
  );
};

const DebugContextProvider = ({ children }) => {
  return (
    <DebugUsersListContextProvider>{children}</DebugUsersListContextProvider>
  );
};
