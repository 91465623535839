import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import React from "react";
import { HeaderContext } from "src/contexts/header/HeaderContext";
import { setSearchOptionDialogOpen } from "src/contexts/header/Reducer";

// スタイル
const useStyles = makeStyles((theme) => ({}));

interface Props {}

export default function Component(props: Props) {
  const classes = useStyles();
  const { stateHeader, dispatchHeader } = React.useContext(HeaderContext);

  const handleDialogOpen = () => {
    setSearchOptionDialogOpen(dispatchHeader, true);
  };
  return (
    <>
      <IconButton color="inherit" onClick={handleDialogOpen}>
        <SearchRoundedIcon />
      </IconButton>
    </>
  );
}
