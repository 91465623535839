import { newState } from "./HeaderContext";

export interface State {
  title: string;
  searchOptionDialogOpen: boolean;
  userMenuDialogOpen: boolean;
}

export type ActionType = {
  type: "TITLE" | "SEARCH_OPTION" | "MENU" | "RESET";
  title?: string;
  searchOptionDialogOpen?: boolean;
  userMenuDialogOpen?: boolean;
};

// useReducerでreducer関数と初期値をセット
export const reducer = (oldState: State, action: ActionType) => {
  switch (action.type) {
    case "TITLE":
      return { ...oldState, title: action.title };
    case "SEARCH_OPTION":
      return {
        ...oldState,
        searchOptionDialogOpen: action.searchOptionDialogOpen,
      };
    case "MENU":
      return { ...oldState, userMenuDialogOpen: action.userMenuDialogOpen };
    case "RESET":
      const state = newState();
      return state;
    default:
      return oldState;
  }
};

export function setTitle(
  dispatchHeader: React.Dispatch<ActionType>,
  title: string
) {
  dispatchHeader({
    type: "TITLE",
    title: title,
  });
}

export function setSearchOptionDialogOpen(
  dispatchHeader: React.Dispatch<ActionType>,
  searchOptionDialogOpen: boolean
) {
  dispatchHeader({
    type: "SEARCH_OPTION",
    searchOptionDialogOpen: searchOptionDialogOpen,
  });
}

export function setUserMenuDialogOpen(
  dispatchHeader: React.Dispatch<ActionType>,
  userMenuDialogOpen: boolean
) {
  dispatchHeader({
    type: "SEARCH_OPTION",
    userMenuDialogOpen: userMenuDialogOpen,
  });
}
