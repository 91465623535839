import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { HeaderContext } from "src/contexts/header/HeaderContext";
import { setUserMenuDialogOpen } from "src/contexts/header/Reducer";

// スタイル
const useStyles = makeStyles((theme) => ({}));

interface Props {}

export default function Component(props: Props) {
  const classes = useStyles();
  const { stateHeader, dispatchHeader } = React.useContext(HeaderContext);

  const handleMenuDialog = () => {
    const dialog = !stateHeader.userMenuDialogOpen;
    setUserMenuDialogOpen(dispatchHeader, dialog);
  };
  return <></>;
}
