import MomentUtils from "@date-io/moment";
import { CssBaseline } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
//import { Elements } from "@stripe/react-stripe-js";
//import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import "moment/locale/ja";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import WrapLayout from "src/components/wrapper/layout/WrapLayout";
import WrapVersionCheck from "src/components/wrapper/WrapVersionCheck";
import Env from "src/consts/environment";
import { ContextProvider } from "./contexts/ContextProvider";
import GlobalStyles from "./GlobalStyles";
import Routes from "./Routes";
import theme from "./theme";
import { IsDevelopment } from "src/functions/Environment";
import { addDevelopMetaTag } from "./addMetaTag";
moment.locale("ja");
class ExtendedMomentUtils extends MomentUtils {
  getCalendarHeaderText(date) {
    return moment(date).format("YYYY年MM月DD日");
  }
  getDatePickerHeaderText(date) {
    return moment(date).format("YYYY年MM月DD日");
  }
}
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe(Env.STRIPE_KEY);

// スタイル
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",

    flexDirection: "column",
  },
}));

export default function Page() {
  // const classes = UseStyles();
  const classes = useStyles();

  React.useEffect(() => {
    let cleanedUp = false;
    if (!cleanedUp) {
      if (IsDevelopment) {
        addDevelopMetaTag();
      }
    }
    const cleanup = () => {
      cleanedUp = true;
    };
    return cleanup;
  }, []);

  return (
    <div className={classes.root} onContextMenu={(e) => e.preventDefault()}>
      {/*
      <Elements stripe={stripePromise}>
      */}
      <ThemeProvider theme={theme()}>
        <CssBaseline />
        <GlobalStyles />
        <main>
          <BrowserRouter>
            <ContextProvider>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={ExtendedMomentUtils}
                locale="ja"
              >
                <WrapLayout>
                  <WrapVersionCheck>
                    <Routes />
                  </WrapVersionCheck>
                </WrapLayout>
              </MuiPickersUtilsProvider>
            </ContextProvider>
          </BrowserRouter>
        </main>
      </ThemeProvider>
      {/*
      </Elements>
      */}
    </div>
  );
}
