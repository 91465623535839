import Env from "src/consts/environment";
import { DebugLog } from "src/functions/Log";

const IsDevelopment = Env.MODE == "development";

function DebugEnvPrint() {
  DebugLog("-- Environment --");
  DebugLog("MODE : %s", Env.MODE);
  DebugLog("ALGOLIA_APIKEY : %s", Env.ALGOLIA_APIKEY);
  DebugLog("ALGOLIA_PROJECT : %s", Env.ALGOLIA_PROJECT);
  DebugLog("FIREBASE_PROJECT_ID : %s", Env.FIREBASE_PROJECT_ID);
  DebugLog("FIREBASE_APIKEY : %s", Env.FIREBASE_APIKEY);
  DebugLog("FIREBASE_AUTHDOMAIN : %s", Env.FIREBASE_AUTHDOMAIN);
  DebugLog("FIREBASE_DATABASE_URL : %s", Env.FIREBASE_DATABASE_URL);
  DebugLog("FIREBASE_STORAGE_BUCKET : %s", Env.FIREBASE_STORAGE_BUCKET);
  DebugLog(
    "FIREBASE_MESSAGING_SENDER_ID : %s",
    Env.FIREBASE_MESSAGING_SENDER_ID
  );
  DebugLog("FIREBASE_APP_ID : %s", Env.FIREBASE_APP_ID);
  DebugLog("FIREBASE_MEASUREMENT_ID : %s", Env.FIREBASE_MEASUREMENT_ID);
  DebugLog(
    "FIREBASE_IS_APPVERIFICATION_DISABLED_FRO_TESTING : %s",
    Env.FIREBASE_IS_APPVERIFICATION_DISABLED_FOR_TESTING
  );
  DebugLog(
    "GOOGLE_ANALITICS_TRACKING_ID : %s",
    Env.GOOGLE_ANALITICS_TRACKING_ID
  );
  DebugLog(
    "GOOGLE_ANALITICS_TRACKING_ID_ADS : %s",
    Env.GOOGLE_ANALITICS_TRACKING_ID_ADS
  );
  DebugLog("STRIPE_KEY : %s", Env.STRIPE_KEY);
  DebugLog("STRIPE_KEY : %s", Env.STRIPE_PRODUCT_ID);
}

export { IsDevelopment, DebugEnvPrint };
