import React from "react";

const UsersListContext = React.createContext(null);
// useReducerでreducer関数と初期値をセット
const reducer = (oldState, action) => ({ ...oldState, ...action });

// Reducer関数の初期値
const initialState = {
  users: [],
  loginSuccess: false,
};

// コンテキストプロバイダーコンポーネント
function DebugUsersListContextProvider(props) {
  // useReducerでreducer関数と初期値をセット
  const [stateDebugUsersList, dispatchDebugUsersList] = React.useReducer(
    reducer,
    initialState
  );
  const value = { stateDebugUsersList, dispatchDebugUsersList };

  return (
    // コンテキストプロバイダーとしてuseReducerのstateとdispatchをコンテキストに設定
    <UsersListContext.Provider value={value}>
      {props.children}
    </UsersListContext.Provider>
  );
}
export { initialState, UsersListContext, DebugUsersListContextProvider };
