import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExploreRoundedIcon from "@material-ui/icons/ExploreRounded";
import FavorIcon from "@material-ui/icons/Favorite";
import ForumRoundedIcon from "@material-ui/icons/ForumRounded";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { LayoutContext } from "src/contexts/layout/LayoutContext";
import { isNotificationDisabled } from "src/functions/Notification";
import { NotificationType } from "src/openapi";
import LoginNotification from "./LoginNotification";

// スタイル
const useStyles = makeStyles((theme) => ({
  wrap: {
    display: "block",
    width: "100%",
    position: "fixed",
    [theme.breakpoints.down("xs")]: {
      left: 0,
    },
    [theme.breakpoints.up("sm")]: {
      width: "600px",
    },
    top: "auto",
    bottom: 0,
    // zIndex: 1000,
    zIndex: theme.zIndex.drawer + 10,
    textAlign: "center",
  },
  bottomNav: {
    // [theme.breakpoints.up("md")]: {
    //   display: "none",
    // },
  },
  button: {
    maxWidth: "100%", // ボタンが横一杯に広がって欲しくない時はコメントアウト
  },
}));

export default withRouter(Components);
function Components(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { stateLayout, dispatchLayout } = React.useContext(LayoutContext);
  const { notificationsMap } = stateLayout;

  const buttons_info = [
    { label: "探す", icon: <ExploreRoundedIcon />, link_to: "/search" },
    // { label: "募集", icon: <PersonPinRoundedIcon />, link_to: "/instantly_board" },
    {
      label: "いいね",
      icon: (
        <Badge
          color="secondary"
          variant="dot"
          invisible={isNotificationDisabled(
            notificationsMap,
            NotificationType.Likes
          )}
        >
          <FavorIcon />
        </Badge>
      ),
      link_to: "/likes",
    },
    {
      label: "やりとり",
      icon: (
        <Badge
          color="secondary"
          variant="dot"
          invisible={
            isNotificationDisabled(
              notificationsMap,
              NotificationType.Matchings
            ) &&
            isNotificationDisabled(
              notificationsMap,
              NotificationType.Messagings
            )
          }
        >
          <ForumRoundedIcon />
        </Badge>
      ),
      link_to: "/contacts",
    },
    {
      label: "掲示板",
      icon: <CalendarTodayIcon />,
      link_to: "/bulletin-boards",
    },
    { label: "マイページ", icon: <AccountCircle />, link_to: "/mypage" },
  ];
  const buttons = buttons_info.map((button_info, index) => {
    return (
      <BottomNavigationAction
        value={button_info.link_to}
        label={button_info.label}
        key={index}
        className={classes.button}
        icon={button_info.icon}
        component={Link}
        to={button_info.link_to}
      />
    );
  });

  return (
    <div className={classes.wrap}>
      {location.pathname === "/search" && <LoginNotification />}
      <Divider />
      <BottomNavigation
        value={props.location.pathname}
        // onChange={(event, newValue) => {
        //   setValue(newValue);
        // }}
        showLabels
        className={classes.bottomNav}
        children={buttons}
      />
    </div>
  );
}
