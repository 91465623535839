import Env from "src/consts/environment";
import {
  AccountApi,
  AmazonGiftCodeApi,
  Configuration,
  LoginNotificationApi,
  NotificationApi,
  VideoCallRoomApi,
  SettlementApi,
} from "src/openapi";

export function accountApi(): AccountApi {
  return new AccountApi(newConfig());
}
export function notificationApi(): NotificationApi {
  return new NotificationApi(newConfig());
}
export function loginNotificationApi(): LoginNotificationApi {
  return new LoginNotificationApi(newConfig());
}
export function amazonGiftCodeApi(): AmazonGiftCodeApi {
  return new AmazonGiftCodeApi(newConfig());
}
export function videoCallRoomApi(): VideoCallRoomApi {
  return new VideoCallRoomApi(newConfig());
}
export function settlementApi(): SettlementApi {
  return new SettlementApi(newConfig());
}
function newConfig(): Configuration {
  return new Configuration({
    baseOptions: {
      // headers: {},
    },
    basePath: Env.BASE_PATH,
  });
}
