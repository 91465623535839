import { Backdrop, CircularProgress, Fade, Paper } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { State } from "src/contexts/layout/Reducer";

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: "absolute",
    minHeight: "100%",
    minWidth: "100%",
    zIndex: theme.zIndex.drawer + 11,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 11,
    color: grey.A700,
  },
  paper: {
    // width: "fit-content",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
  circle: {
    marginBottom: "-4px",
  },
}));

interface Props {
  state: State["loading"];
}

export default function Components(props: Props) {
  const classes = useStyles();
  const delay = props.state.type === "FETCHING" ? "200ms" : "0ms";
  return (
    <React.Fragment>
      <Fade
        in={true}
        style={{
          transitionDelay: delay,
        }}
        unmountOnExit
      >
        <Backdrop
          invisible={props.state.type === "FETCHING"}
          className={classes.backdrop}
          open={true}
          // onClick={handleClickLoading}
        >
          <Paper className={classes.paper}>
            <CircularProgress className={classes.circle} />
          </Paper>
        </Backdrop>
      </Fade>
    </React.Fragment>
  );
}
