import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import BottomNavigation from "src/components/BottomNavigation";
import Header from "src/components/header/Header";
import { LayoutContext } from "src/contexts/layout/LayoutContext";
import { MatchingInRegexes } from "src/functions/Regex";
import { AuthContext } from "src/contexts/AuthContext";
import OverlayDialog from "./OverlayDialog";
import Loading from "src/components/parts/loading/Loading";
import ScrollToTop from "./ScrollToTop";
import { Consts } from "src/consts/consts";
import { makeStyles } from "@material-ui/core/styles";

// スタイル
const useStyles = makeStyles((theme) => ({
  layoutTop: {
    width: "auto",
    paddingBottom: theme.spacing(6),
  },
  layout: {
    width: "auto",
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up(Consts.MAX_WIDTH + theme.spacing(2) * 2)]: {
      width: Consts.MAX_WIDTH,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

// アプリバー・ボトムナビ非表示
const disabledDecorationPaths = [
  "/signup",
  "/signup-facebook",
  "/login",
  "/login-facebook",
];

// アプリバー・ボトムナビ非表示(正規表現)
const disabledDecorationPathRegexes = ["/requests/(.*)$"];

// ボトムナビだけ非表示
const disabledBottomNavigationPaths = [
  "/",
  "/company",
  "/privacy-policy",
  "/terms-of-service",
  "/notation-law",
  "/plans",
  "/change-password",
  "/requests",
  "/medias",
  "/affiliate",
];

// アプリバー・ボトムナビ非表示(正規表現)
const disabledBottomNavigationPathRegexes = ["/verify-email/(.*)$"];

// ダイアログ除外対象
const dialogExcludePaths = ["/", "/help", "/debug_top"];

export default function Components(props: Props) {
  const classes = useStyles();
  const { stateLayout, dispatchLayout } = React.useContext(LayoutContext);
  const { stateAuth, dispatchAuth } = React.useContext(AuthContext);
  const location = useLocation();
  const [dialog, setDialog] = React.useState(false);
  const handleDialog = () => {
    setDialog(!dialog);
  };

  useEffect(() => {
    let cleanedUp = false;
    if (!cleanedUp) {
      // const path = location.pathname;
      // setDialog(
      //   dialogExcludePaths.includes(path) &&
      //     dialog &&
      //     stateLayout.dialog.enabled
      // );
    }
    const cleanup = () => {
      cleanedUp = true;
    };
    return cleanup;
  }, []);

  const Memo = useMemo(() => {
    const path = location.pathname;
    setDialog(!dialogExcludePaths.includes(path) && stateLayout.dialog.enabled);
    const decolation = !(
      disabledDecorationPaths.includes(path) ||
      MatchingInRegexes(path, disabledDecorationPathRegexes)
    );
    const disabledBottomNavigation =
      disabledBottomNavigationPaths.includes(path) ||
      MatchingInRegexes(path, disabledBottomNavigationPathRegexes);
    return (
      <div className={path === "/" ? classes.layoutTop : classes.layout}>
        <ScrollToTop />
        {decolation ? (
          <React.Fragment>
            <Header>{props.children}</Header>
            {!disabledBottomNavigation && <BottomNavigation />}
          </React.Fragment>
        ) : (
          props.children
        )}
        {dialog && <OverlayDialog handleDialog={handleDialog} />}
        {stateLayout.loading.enabled && <Loading state={stateLayout.loading} />}
      </div>
    );
  }, [location, stateLayout, dialog, stateAuth]);
  return <React.Fragment>{Memo}</React.Fragment>;
}
