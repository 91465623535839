import axios from "axios";
import { History } from "history";
import version from "src/consts/json/version.json";
import { HandleHttpErrorResponse } from "src/functions/Error";

function VersionCheck(
  history: History<unknown>,
  dispatchLayout: React.Dispatch<any>
) {
  let clientVersion = version.data[0].version;
  let requestParams = { version: clientVersion };
  axios
    .get("/api/system/version_check", {
      params: requestParams,
    })
    .then((res) => {
      if (!res.data.result) {
        console.log("illegal version");
        window.location.reload();
      }
    })
    .catch((error) => {
      HandleHttpErrorResponse(error, dispatchLayout);
    });
}

export { VersionCheck };
