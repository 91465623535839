/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Tutorial
 * OpenAPI Tutorial by halhorn
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 * request props of account/create
 * @export
 * @interface AccountCreateInput
 */
export interface AccountCreateInput {
  /**
   *
   * @type {string}
   * @memberof AccountCreateInput
   */
  sub?: string;
  /**
   *
   * @type {string}
   * @memberof AccountCreateInput
   */
  email?: string;
}
/**
 * create account
 * @export
 * @interface AccountCreateOutput
 */
export interface AccountCreateOutput {
  /**
   *
   * @type {User}
   * @memberof AccountCreateOutput
   */
  user: User;
}
/**
 * list account
 * @export
 * @interface AccountListOutput
 */
export interface AccountListOutput {
  /**
   *
   * @type {User}
   * @memberof AccountListOutput
   */
  user: User;
}
/**
 * request props of amazon_gift_code/create
 * @export
 * @interface AmazonGiftCodeCreateInput
 */
export interface AmazonGiftCodeCreateInput {
  /**
   *
   * @type {string}
   * @memberof AmazonGiftCodeCreateInput
   */
  generatorId?: string;
}
/**
 * create amazon_gift_code
 * @export
 * @interface AmazonGiftCodeCreateOutput
 */
export interface AmazonGiftCodeCreateOutput {
  /**
   *
   * @type {string}
   * @memberof AmazonGiftCodeCreateOutput
   */
  code?: string;
  /**
   *
   * @type {boolean}
   * @memberof AmazonGiftCodeCreateOutput
   */
  used?: boolean;
}
/**
 *
 * @export
 * @interface LoginNotification
 */
export interface LoginNotification {
  /**
   *
   * @type {string}
   * @memberof LoginNotification
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof LoginNotification
   */
  userId?: string;
  /**
   *
   * @type {LoginNotificationType}
   * @memberof LoginNotification
   */
  type?: LoginNotificationType;
  /**
   *
   * @type {boolean}
   * @memberof LoginNotification
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof LoginNotification
   */
  updatedAt?: string;
}
/**
 * list login_notification
 * @export
 * @interface LoginNotificationListOutput
 */
export interface LoginNotificationListOutput {
  /**
   *
   * @type {Array<LoginNotification>}
   * @memberof LoginNotificationListOutput
   */
  loginNotifications?: Array<LoginNotification>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum LoginNotificationType {
  Likes = "Likes",
  Matchings = "Matchings",
  Messagings = "Messagings",
}

/**
 * request props of login_notification/update
 * @export
 * @interface LoginNotificationUpdateInput
 */
export interface LoginNotificationUpdateInput {
  /**
   *
   * @type {Array<LoginNotificationType>}
   * @memberof LoginNotificationUpdateInput
   */
  types?: Array<LoginNotificationType>;
}
/**
 *
 * @export
 * @interface Notification
 */
export interface Notification {
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  userId?: string;
  /**
   *
   * @type {NotificationType}
   * @memberof Notification
   */
  type?: NotificationType;
  /**
   *
   * @type {boolean}
   * @memberof Notification
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  updatedAt?: string;
}
/**
 * list notification
 * @export
 * @interface NotificationListOutput
 */
export interface NotificationListOutput {
  /**
   *
   * @type {Array<Notification>}
   * @memberof NotificationListOutput
   */
  notifications?: Array<Notification>;
  /**
   *
   * @type {number}
   * @memberof NotificationListOutput
   */
  total?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum NotificationType {
  Likes = "Likes",
  Matchings = "Matchings",
  Messagings = "Messagings",
  Informations = "Informations",
  Visitors = "Visitors",
  AgeCertifications = "AgeCertifications",
}

/**
 * request props of notification/update
 * @export
 * @interface NotificationUpdateInput
 */
export interface NotificationUpdateInput {
  /**
   *
   * @type {string}
   * @memberof NotificationUpdateInput
   */
  userId?: string;
  /**
   *
   * @type {NotificationType}
   * @memberof NotificationUpdateInput
   */
  type?: NotificationType;
  /**
   *
   * @type {boolean}
   * @memberof NotificationUpdateInput
   */
  enabled?: boolean;
}
/**
 *
 * @export
 * @interface PeerConnectionData
 */
export interface PeerConnectionData {
  /**
   *
   * @type {string}
   * @memberof PeerConnectionData
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof PeerConnectionData
   */
  sdp?: string;
}
/**
 * request props of cancel subscription
 * @export
 * @interface SettlementCancelSubscriptionInput
 */
export interface SettlementCancelSubscriptionInput {
  /**
   *
   * @type {string}
   * @memberof SettlementCancelSubscriptionInput
   */
  subscriptionId: string;
}
/**
 * fetch subscriptions
 * @export
 * @interface SettlementFetchSubscriptionsOutput
 */
export interface SettlementFetchSubscriptionsOutput {
  /**
   *
   * @type {Array<Subscription>}
   * @memberof SettlementFetchSubscriptionsOutput
   */
  subscriptions: Array<Subscription>;
  /**
   *
   * @type {boolean}
   * @memberof SettlementFetchSubscriptionsOutput
   */
  isPaid: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SettlementFetchSubscriptionsOutput
   */
  hasModifiedSubscription: boolean;
}
/**
 * request props of reactive subscription
 * @export
 * @interface SettlementReactiveSubscriptionInput
 */
export interface SettlementReactiveSubscriptionInput {
  /**
   *
   * @type {string}
   * @memberof SettlementReactiveSubscriptionInput
   */
  subscriptionId: string;
}
/**
 *
 * @export
 * @interface Subscription
 */
export interface Subscription {
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  customerId?: string;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  planId?: string;
  /**
   *
   * @type {number}
   * @memberof Subscription
   */
  periodStart?: number;
  /**
   *
   * @type {number}
   * @memberof Subscription
   */
  periodEnd?: number;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  periodStartAt?: string;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  periodEndAt?: string;
  /**
   *
   * @type {number}
   * @memberof Subscription
   */
  settleCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof Subscription
   */
  cancelAtPeriodEnd?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Subscription
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Subscription
   */
  isModified?: boolean;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  name?: string;
}
/**
 *
 * @export
 * @interface VideoCallRoom
 */
export interface VideoCallRoom {
  /**
   *
   * @type {string}
   * @memberof VideoCallRoom
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VideoCallRoom
   */
  offerUserId?: string;
  /**
   *
   * @type {string}
   * @memberof VideoCallRoom
   */
  answerUserId?: string;
  /**
   *
   * @type {PeerConnectionData}
   * @memberof VideoCallRoom
   */
  offer?: PeerConnectionData;
  /**
   *
   * @type {PeerConnectionData}
   * @memberof VideoCallRoom
   */
  answer?: PeerConnectionData;
  /**
   *
   * @type {VideoCallRoomStatus}
   * @memberof VideoCallRoom
   */
  status?: VideoCallRoomStatus;
  /**
   *
   * @type {string}
   * @memberof VideoCallRoom
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof VideoCallRoom
   */
  updatedAt?: string;
}
/**
 * request props of video_call_room/create
 * @export
 * @interface VideoCallRoomCreateInput
 */
export interface VideoCallRoomCreateInput {
  /**
   *
   * @type {string}
   * @memberof VideoCallRoomCreateInput
   */
  offerUserId?: string;
  /**
   *
   * @type {string}
   * @memberof VideoCallRoomCreateInput
   */
  answerUserId?: string;
  /**
   *
   * @type {string}
   * @memberof VideoCallRoomCreateInput
   */
  matchingId?: string;
}
/**
 * create video_call_room
 * @export
 * @interface VideoCallRoomCreateOutput
 */
export interface VideoCallRoomCreateOutput {
  /**
   *
   * @type {string}
   * @memberof VideoCallRoomCreateOutput
   */
  roomId?: string;
}
/**
 * request props of video_call_room/delete
 * @export
 * @interface VideoCallRoomDeleteInput
 */
export interface VideoCallRoomDeleteInput {
  /**
   *
   * @type {string}
   * @memberof VideoCallRoomDeleteInput
   */
  roomId?: string;
  /**
   *
   * @type {VideoCallRoomStatus}
   * @memberof VideoCallRoomDeleteInput
   */
  status?: VideoCallRoomStatus;
}
/**
 * delete video_call_room
 * @export
 * @interface VideoCallRoomDeleteOutput
 */
export interface VideoCallRoomDeleteOutput {
  /**
   *
   * @type {VideoCallRoom}
   * @memberof VideoCallRoomDeleteOutput
   */
  videoCallRoom: VideoCallRoom;
}
/**
 * fetch video_call_room
 * @export
 * @interface VideoCallRoomFetchOutput
 */
export interface VideoCallRoomFetchOutput {
  /**
   *
   * @type {VideoCallRoom}
   * @memberof VideoCallRoomFetchOutput
   */
  videoCallRoom: VideoCallRoom;
}
/**
 * list video_call_room
 * @export
 * @interface VideoCallRoomListOutput
 */
export interface VideoCallRoomListOutput {
  /**
   *
   * @type {Array<VideoCallRoom>}
   * @memberof VideoCallRoomListOutput
   */
  videoCallRooms: Array<VideoCallRoom>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum VideoCallRoomStatus {
  CreatedRoom = "CreatedRoom",
  Called = "Called",
  Canceled = "Canceled",
  Busy = "Busy",
  TimePassed = "TimePassed",
  HungUp = "HungUp",
  TimeIsUp = "TimeIsUp",
}

/**
 * request props of video_call_room/update
 * @export
 * @interface VideoCallRoomUpdateInput
 */
export interface VideoCallRoomUpdateInput {
  /**
   *
   * @type {string}
   * @memberof VideoCallRoomUpdateInput
   */
  roomId?: string;
  /**
   *
   * @type {PeerConnectionData}
   * @memberof VideoCallRoomUpdateInput
   */
  offer?: PeerConnectionData;
  /**
   *
   * @type {PeerConnectionData}
   * @memberof VideoCallRoomUpdateInput
   */
  answer?: PeerConnectionData;
  /**
   *
   * @type {VideoCallRoomStatus}
   * @memberof VideoCallRoomUpdateInput
   */
  status?: VideoCallRoomStatus;
}
/**
 * update video_call_room
 * @export
 * @interface VideoCallRoomUpdateOutput
 */
export interface VideoCallRoomUpdateOutput {
  /**
   *
   * @type {VideoCallRoom}
   * @memberof VideoCallRoomUpdateOutput
   */
  videoCallRoom: VideoCallRoom;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * create account
     * @summary create account
     * @param {AccountCreateInput} [accountCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountCreate: async (
      accountCreateInput?: AccountCreateInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/account/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accountCreateInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * list account
     * @summary list account
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountList: async (
      body?: object,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/account/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration);
  return {
    /**
     * create account
     * @summary create account
     * @param {AccountCreateInput} [accountCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountCreate(
      accountCreateInput?: AccountCreateInput,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountCreateOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountCreate(
        accountCreateInput,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * list account
     * @summary list account
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountList(
      body?: object,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountListOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountList(
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AccountApiFp(configuration);
  return {
    /**
     * create account
     * @summary create account
     * @param {AccountCreateInput} [accountCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountCreate(
      accountCreateInput?: AccountCreateInput,
      options?: any
    ): AxiosPromise<AccountCreateOutput> {
      return localVarFp
        .accountCreate(accountCreateInput, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * list account
     * @summary list account
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountList(body?: object, options?: any): AxiosPromise<AccountListOutput> {
      return localVarFp
        .accountList(body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
  /**
   * create account
   * @summary create account
   * @param {AccountCreateInput} [accountCreateInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountCreate(accountCreateInput?: AccountCreateInput, options?: any) {
    return AccountApiFp(this.configuration)
      .accountCreate(accountCreateInput, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * list account
   * @summary list account
   * @param {object} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountList(body?: object, options?: any) {
    return AccountApiFp(this.configuration)
      .accountList(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AmazonGiftCodeApi - axios parameter creator
 * @export
 */
export const AmazonGiftCodeApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * create amazon_gift_code
     * @summary create amazon_gift_code
     * @param {AmazonGiftCodeCreateInput} [amazonGiftCodeCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    amazonGiftCodeCreate: async (
      amazonGiftCodeCreateInput?: AmazonGiftCodeCreateInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/amazon_gift_code/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        amazonGiftCodeCreateInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AmazonGiftCodeApi - functional programming interface
 * @export
 */
export const AmazonGiftCodeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AmazonGiftCodeApiAxiosParamCreator(configuration);
  return {
    /**
     * create amazon_gift_code
     * @summary create amazon_gift_code
     * @param {AmazonGiftCodeCreateInput} [amazonGiftCodeCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async amazonGiftCodeCreate(
      amazonGiftCodeCreateInput?: AmazonGiftCodeCreateInput,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AmazonGiftCodeCreateOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.amazonGiftCodeCreate(
          amazonGiftCodeCreateInput,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AmazonGiftCodeApi - factory interface
 * @export
 */
export const AmazonGiftCodeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AmazonGiftCodeApiFp(configuration);
  return {
    /**
     * create amazon_gift_code
     * @summary create amazon_gift_code
     * @param {AmazonGiftCodeCreateInput} [amazonGiftCodeCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    amazonGiftCodeCreate(
      amazonGiftCodeCreateInput?: AmazonGiftCodeCreateInput,
      options?: any
    ): AxiosPromise<AmazonGiftCodeCreateOutput> {
      return localVarFp
        .amazonGiftCodeCreate(amazonGiftCodeCreateInput, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AmazonGiftCodeApi - object-oriented interface
 * @export
 * @class AmazonGiftCodeApi
 * @extends {BaseAPI}
 */
export class AmazonGiftCodeApi extends BaseAPI {
  /**
   * create amazon_gift_code
   * @summary create amazon_gift_code
   * @param {AmazonGiftCodeCreateInput} [amazonGiftCodeCreateInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AmazonGiftCodeApi
   */
  public amazonGiftCodeCreate(
    amazonGiftCodeCreateInput?: AmazonGiftCodeCreateInput,
    options?: any
  ) {
    return AmazonGiftCodeApiFp(this.configuration)
      .amazonGiftCodeCreate(amazonGiftCodeCreateInput, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LoginNotificationApi - axios parameter creator
 * @export
 */
export const LoginNotificationApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * list login_notification
     * @summary list login_notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginNotificationList: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/login-notification/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * update login_notification
     * @summary update login_notification
     * @param {LoginNotificationUpdateInput} [loginNotificationUpdateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginNotificationUpdate: async (
      loginNotificationUpdateInput?: LoginNotificationUpdateInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/login-notification/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginNotificationUpdateInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LoginNotificationApi - functional programming interface
 * @export
 */
export const LoginNotificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    LoginNotificationApiAxiosParamCreator(configuration);
  return {
    /**
     * list login_notification
     * @summary list login_notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loginNotificationList(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<LoginNotificationListOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.loginNotificationList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * update login_notification
     * @summary update login_notification
     * @param {LoginNotificationUpdateInput} [loginNotificationUpdateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loginNotificationUpdate(
      loginNotificationUpdateInput?: LoginNotificationUpdateInput,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.loginNotificationUpdate(
          loginNotificationUpdateInput,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * LoginNotificationApi - factory interface
 * @export
 */
export const LoginNotificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = LoginNotificationApiFp(configuration);
  return {
    /**
     * list login_notification
     * @summary list login_notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginNotificationList(
      options?: any
    ): AxiosPromise<LoginNotificationListOutput> {
      return localVarFp
        .loginNotificationList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * update login_notification
     * @summary update login_notification
     * @param {LoginNotificationUpdateInput} [loginNotificationUpdateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginNotificationUpdate(
      loginNotificationUpdateInput?: LoginNotificationUpdateInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .loginNotificationUpdate(loginNotificationUpdateInput, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * LoginNotificationApi - object-oriented interface
 * @export
 * @class LoginNotificationApi
 * @extends {BaseAPI}
 */
export class LoginNotificationApi extends BaseAPI {
  /**
   * list login_notification
   * @summary list login_notification
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginNotificationApi
   */
  public loginNotificationList(options?: any) {
    return LoginNotificationApiFp(this.configuration)
      .loginNotificationList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * update login_notification
   * @summary update login_notification
   * @param {LoginNotificationUpdateInput} [loginNotificationUpdateInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginNotificationApi
   */
  public loginNotificationUpdate(
    loginNotificationUpdateInput?: LoginNotificationUpdateInput,
    options?: any
  ) {
    return LoginNotificationApiFp(this.configuration)
      .loginNotificationUpdate(loginNotificationUpdateInput, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * list notification
     * @summary list notification
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationList: async (
      userId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("notificationList", "userId", userId);
      const localVarPath = `/notification/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * update notification
     * @summary update notification
     * @param {NotificationUpdateInput} [notificationUpdateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationUpdate: async (
      notificationUpdateInput?: NotificationUpdateInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/notification/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationUpdateInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    NotificationApiAxiosParamCreator(configuration);
  return {
    /**
     * list notification
     * @summary list notification
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationList(
      userId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<NotificationListOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationList(userId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * update notification
     * @summary update notification
     * @param {NotificationUpdateInput} [notificationUpdateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationUpdate(
      notificationUpdateInput?: NotificationUpdateInput,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationUpdate(
          notificationUpdateInput,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NotificationApiFp(configuration);
  return {
    /**
     * list notification
     * @summary list notification
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationList(
      userId: string,
      options?: any
    ): AxiosPromise<NotificationListOutput> {
      return localVarFp
        .notificationList(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * update notification
     * @summary update notification
     * @param {NotificationUpdateInput} [notificationUpdateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationUpdate(
      notificationUpdateInput?: NotificationUpdateInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .notificationUpdate(notificationUpdateInput, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
  /**
   * list notification
   * @summary list notification
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public notificationList(userId: string, options?: any) {
    return NotificationApiFp(this.configuration)
      .notificationList(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * update notification
   * @summary update notification
   * @param {NotificationUpdateInput} [notificationUpdateInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public notificationUpdate(
    notificationUpdateInput?: NotificationUpdateInput,
    options?: any
  ) {
    return NotificationApiFp(this.configuration)
      .notificationUpdate(notificationUpdateInput, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SettlementApi - axios parameter creator
 * @export
 */
export const SettlementApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * cancel subscription
     * @summary cancel subscription
     * @param {SettlementCancelSubscriptionInput} [settlementCancelSubscriptionInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settlementCancelSubscription: async (
      settlementCancelSubscriptionInput?: SettlementCancelSubscriptionInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/settlement/subscriptions/cancel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        settlementCancelSubscriptionInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * fetch subscriptions
     * @summary fetch subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settlementFetchSubscriptions: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/settlement/subscriptions/fetch`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * reactive subscription
     * @summary reactive subscription
     * @param {SettlementReactiveSubscriptionInput} [settlementReactiveSubscriptionInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settlementReactiveSubscription: async (
      settlementReactiveSubscriptionInput?: SettlementReactiveSubscriptionInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/settlement/subscriptions/reactive`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        settlementReactiveSubscriptionInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SettlementApi - functional programming interface
 * @export
 */
export const SettlementApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SettlementApiAxiosParamCreator(configuration);
  return {
    /**
     * cancel subscription
     * @summary cancel subscription
     * @param {SettlementCancelSubscriptionInput} [settlementCancelSubscriptionInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settlementCancelSubscription(
      settlementCancelSubscriptionInput?: SettlementCancelSubscriptionInput,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.settlementCancelSubscription(
          settlementCancelSubscriptionInput,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * fetch subscriptions
     * @summary fetch subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settlementFetchSubscriptions(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<SettlementFetchSubscriptionsOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.settlementFetchSubscriptions(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * reactive subscription
     * @summary reactive subscription
     * @param {SettlementReactiveSubscriptionInput} [settlementReactiveSubscriptionInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settlementReactiveSubscription(
      settlementReactiveSubscriptionInput?: SettlementReactiveSubscriptionInput,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.settlementReactiveSubscription(
          settlementReactiveSubscriptionInput,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * SettlementApi - factory interface
 * @export
 */
export const SettlementApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SettlementApiFp(configuration);
  return {
    /**
     * cancel subscription
     * @summary cancel subscription
     * @param {SettlementCancelSubscriptionInput} [settlementCancelSubscriptionInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settlementCancelSubscription(
      settlementCancelSubscriptionInput?: SettlementCancelSubscriptionInput,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .settlementCancelSubscription(
          settlementCancelSubscriptionInput,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * fetch subscriptions
     * @summary fetch subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settlementFetchSubscriptions(
      options?: any
    ): AxiosPromise<SettlementFetchSubscriptionsOutput> {
      return localVarFp
        .settlementFetchSubscriptions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * reactive subscription
     * @summary reactive subscription
     * @param {SettlementReactiveSubscriptionInput} [settlementReactiveSubscriptionInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settlementReactiveSubscription(
      settlementReactiveSubscriptionInput?: SettlementReactiveSubscriptionInput,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .settlementReactiveSubscription(
          settlementReactiveSubscriptionInput,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SettlementApi - object-oriented interface
 * @export
 * @class SettlementApi
 * @extends {BaseAPI}
 */
export class SettlementApi extends BaseAPI {
  /**
   * cancel subscription
   * @summary cancel subscription
   * @param {SettlementCancelSubscriptionInput} [settlementCancelSubscriptionInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettlementApi
   */
  public settlementCancelSubscription(
    settlementCancelSubscriptionInput?: SettlementCancelSubscriptionInput,
    options?: any
  ) {
    return SettlementApiFp(this.configuration)
      .settlementCancelSubscription(settlementCancelSubscriptionInput, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * fetch subscriptions
   * @summary fetch subscriptions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettlementApi
   */
  public settlementFetchSubscriptions(options?: any) {
    return SettlementApiFp(this.configuration)
      .settlementFetchSubscriptions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * reactive subscription
   * @summary reactive subscription
   * @param {SettlementReactiveSubscriptionInput} [settlementReactiveSubscriptionInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettlementApi
   */
  public settlementReactiveSubscription(
    settlementReactiveSubscriptionInput?: SettlementReactiveSubscriptionInput,
    options?: any
  ) {
    return SettlementApiFp(this.configuration)
      .settlementReactiveSubscription(
        settlementReactiveSubscriptionInput,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VideoCallRoomApi - axios parameter creator
 * @export
 */
export const VideoCallRoomApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * create video_call_room
     * @summary create video_call_room
     * @param {VideoCallRoomCreateInput} [videoCallRoomCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoCallRoomCreate: async (
      videoCallRoomCreateInput?: VideoCallRoomCreateInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/video-call-rooms/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        videoCallRoomCreateInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * delete video_call_room
     * @summary delete video_call_room
     * @param {VideoCallRoomDeleteInput} [videoCallRoomDeleteInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoCallRoomDelete: async (
      videoCallRoomDeleteInput?: VideoCallRoomDeleteInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/video-call-rooms/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        videoCallRoomDeleteInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * fetch video_call_room
     * @summary fetch video_call_room
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoCallRoomFetch: async (
      roomId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomId' is not null or undefined
      assertParamExists("videoCallRoomFetch", "roomId", roomId);
      const localVarPath = `/video-call-rooms/fetch`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (roomId !== undefined) {
        localVarQueryParameter["roomId"] = roomId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * list video_call_room
     * @summary list video_call_room
     * @param {string} offerUserId
     * @param {string} answerUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoCallRoomList: async (
      offerUserId: string,
      answerUserId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'offerUserId' is not null or undefined
      assertParamExists("videoCallRoomList", "offerUserId", offerUserId);
      // verify required parameter 'answerUserId' is not null or undefined
      assertParamExists("videoCallRoomList", "answerUserId", answerUserId);
      const localVarPath = `/video-call-rooms/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (offerUserId !== undefined) {
        localVarQueryParameter["offerUserId"] = offerUserId;
      }

      if (answerUserId !== undefined) {
        localVarQueryParameter["answerUserId"] = answerUserId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * update video_call_room
     * @summary update video_call_room
     * @param {VideoCallRoomUpdateInput} [videoCallRoomUpdateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoCallRoomUpdate: async (
      videoCallRoomUpdateInput?: VideoCallRoomUpdateInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/video-call-rooms/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        videoCallRoomUpdateInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * VideoCallRoomApi - functional programming interface
 * @export
 */
export const VideoCallRoomApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    VideoCallRoomApiAxiosParamCreator(configuration);
  return {
    /**
     * create video_call_room
     * @summary create video_call_room
     * @param {VideoCallRoomCreateInput} [videoCallRoomCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async videoCallRoomCreate(
      videoCallRoomCreateInput?: VideoCallRoomCreateInput,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VideoCallRoomCreateOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.videoCallRoomCreate(
          videoCallRoomCreateInput,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * delete video_call_room
     * @summary delete video_call_room
     * @param {VideoCallRoomDeleteInput} [videoCallRoomDeleteInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async videoCallRoomDelete(
      videoCallRoomDeleteInput?: VideoCallRoomDeleteInput,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VideoCallRoomDeleteOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.videoCallRoomDelete(
          videoCallRoomDeleteInput,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * fetch video_call_room
     * @summary fetch video_call_room
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async videoCallRoomFetch(
      roomId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VideoCallRoomFetchOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.videoCallRoomFetch(roomId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * list video_call_room
     * @summary list video_call_room
     * @param {string} offerUserId
     * @param {string} answerUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async videoCallRoomList(
      offerUserId: string,
      answerUserId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VideoCallRoomListOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.videoCallRoomList(
          offerUserId,
          answerUserId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * update video_call_room
     * @summary update video_call_room
     * @param {VideoCallRoomUpdateInput} [videoCallRoomUpdateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async videoCallRoomUpdate(
      videoCallRoomUpdateInput?: VideoCallRoomUpdateInput,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VideoCallRoomUpdateOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.videoCallRoomUpdate(
          videoCallRoomUpdateInput,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * VideoCallRoomApi - factory interface
 * @export
 */
export const VideoCallRoomApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = VideoCallRoomApiFp(configuration);
  return {
    /**
     * create video_call_room
     * @summary create video_call_room
     * @param {VideoCallRoomCreateInput} [videoCallRoomCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoCallRoomCreate(
      videoCallRoomCreateInput?: VideoCallRoomCreateInput,
      options?: any
    ): AxiosPromise<VideoCallRoomCreateOutput> {
      return localVarFp
        .videoCallRoomCreate(videoCallRoomCreateInput, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * delete video_call_room
     * @summary delete video_call_room
     * @param {VideoCallRoomDeleteInput} [videoCallRoomDeleteInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoCallRoomDelete(
      videoCallRoomDeleteInput?: VideoCallRoomDeleteInput,
      options?: any
    ): AxiosPromise<VideoCallRoomDeleteOutput> {
      return localVarFp
        .videoCallRoomDelete(videoCallRoomDeleteInput, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * fetch video_call_room
     * @summary fetch video_call_room
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoCallRoomFetch(
      roomId: string,
      options?: any
    ): AxiosPromise<VideoCallRoomFetchOutput> {
      return localVarFp
        .videoCallRoomFetch(roomId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * list video_call_room
     * @summary list video_call_room
     * @param {string} offerUserId
     * @param {string} answerUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoCallRoomList(
      offerUserId: string,
      answerUserId: string,
      options?: any
    ): AxiosPromise<VideoCallRoomListOutput> {
      return localVarFp
        .videoCallRoomList(offerUserId, answerUserId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * update video_call_room
     * @summary update video_call_room
     * @param {VideoCallRoomUpdateInput} [videoCallRoomUpdateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoCallRoomUpdate(
      videoCallRoomUpdateInput?: VideoCallRoomUpdateInput,
      options?: any
    ): AxiosPromise<VideoCallRoomUpdateOutput> {
      return localVarFp
        .videoCallRoomUpdate(videoCallRoomUpdateInput, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * VideoCallRoomApi - object-oriented interface
 * @export
 * @class VideoCallRoomApi
 * @extends {BaseAPI}
 */
export class VideoCallRoomApi extends BaseAPI {
  /**
   * create video_call_room
   * @summary create video_call_room
   * @param {VideoCallRoomCreateInput} [videoCallRoomCreateInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VideoCallRoomApi
   */
  public videoCallRoomCreate(
    videoCallRoomCreateInput?: VideoCallRoomCreateInput,
    options?: any
  ) {
    return VideoCallRoomApiFp(this.configuration)
      .videoCallRoomCreate(videoCallRoomCreateInput, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * delete video_call_room
   * @summary delete video_call_room
   * @param {VideoCallRoomDeleteInput} [videoCallRoomDeleteInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VideoCallRoomApi
   */
  public videoCallRoomDelete(
    videoCallRoomDeleteInput?: VideoCallRoomDeleteInput,
    options?: any
  ) {
    return VideoCallRoomApiFp(this.configuration)
      .videoCallRoomDelete(videoCallRoomDeleteInput, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * fetch video_call_room
   * @summary fetch video_call_room
   * @param {string} roomId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VideoCallRoomApi
   */
  public videoCallRoomFetch(roomId: string, options?: any) {
    return VideoCallRoomApiFp(this.configuration)
      .videoCallRoomFetch(roomId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * list video_call_room
   * @summary list video_call_room
   * @param {string} offerUserId
   * @param {string} answerUserId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VideoCallRoomApi
   */
  public videoCallRoomList(
    offerUserId: string,
    answerUserId: string,
    options?: any
  ) {
    return VideoCallRoomApiFp(this.configuration)
      .videoCallRoomList(offerUserId, answerUserId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * update video_call_room
   * @summary update video_call_room
   * @param {VideoCallRoomUpdateInput} [videoCallRoomUpdateInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VideoCallRoomApi
   */
  public videoCallRoomUpdate(
    videoCallRoomUpdateInput?: VideoCallRoomUpdateInput,
    options?: any
  ) {
    return VideoCallRoomApiFp(this.configuration)
      .videoCallRoomUpdate(videoCallRoomUpdateInput, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
