import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { UpdateLoginNotification } from "src/action/LoginNotification";
import { LayoutContext } from "src/contexts/layout/LayoutContext";
import {
  setLikesVisible,
  setMatchingsVisible,
  setMessagingsVisible,
} from "src/contexts/layout/Reducer";
import {
  LoginNotificationType,
  LoginNotificationUpdateInput,
} from "src/openapi";
import Balloon from "./Balloon";

const useStyles = makeStyles((theme) => ({
  grid: {
    width: "70%",
    margin: "0 0 0 auto",
  },
}));

export default function Component() {
  const classes = useStyles();
  const { stateLayout, dispatchLayout } = React.useContext(LayoutContext);
  const { messagingsVisible, matchingsVisible, likesVisible } = stateLayout;

  if (!messagingsVisible && !matchingsVisible && !likesVisible) {
    return <></>;
  }

  const handleRemoveMessagingsBalloon = () => {
    if (messagingsVisible) {
      UpdateLoginNotification(createInput(), dispatchLayout);
    }
    setTimeout(() => {
      setMessagingsVisible(dispatchLayout, false);
    }, 5000);
  };

  const handleRemoveMatchingsBalloon = () => {
    if (!messagingsVisible && matchingsVisible) {
      UpdateLoginNotification(createInput(), dispatchLayout);
    }
    setTimeout(() => {
      setMatchingsVisible(dispatchLayout, false);
    }, 5000);
  };

  const handleRemoveLikesBalloon = () => {
    if (!messagingsVisible && !matchingsVisible && likesVisible) {
      UpdateLoginNotification(createInput(), dispatchLayout);
    }
    setTimeout(() => {
      setLikesVisible(dispatchLayout, false);
    }, 5000);
  };

  function createInput() {
    const types: LoginNotificationType[] = [];
    if (messagingsVisible) {
      types.push(LoginNotificationType.Messagings);
    }
    if (matchingsVisible) {
      types.push(LoginNotificationType.Matchings);
    }
    if (likesVisible) {
      types.push(LoginNotificationType.Likes);
    }
    const input: LoginNotificationUpdateInput = { types: types };
    return input;
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-end"
      spacing={2}
      className={classes.grid}
    >
      {messagingsVisible && (
        <Grid item xs={12}>
          <Balloon handleRemoveBalloon={handleRemoveMessagingsBalloon}>
            新着メッセージがあります！
          </Balloon>
        </Grid>
      )}
      {matchingsVisible && (
        <Grid item xs={12}>
          <Balloon handleRemoveBalloon={handleRemoveMatchingsBalloon}>
            マッチングしました！
          </Balloon>
        </Grid>
      )}
      {likesVisible && (
        <Grid item xs={12}>
          <Balloon handleRemoveBalloon={handleRemoveLikesBalloon}>
            新着いいね！があります！
          </Balloon>
        </Grid>
      )}
    </Grid>
  );
}
