import { Notification, NotificationType } from "src/openapi";

export type LoadingType = "PROCESSING" | "LOADING";

export interface State {
  header: boolean;
  bottomNavi: boolean;
  notificationsMap: Map<NotificationType, Notification>;
  messagingsVisible: boolean;
  matchingsVisible: boolean;
  likesVisible: boolean;
  loading?: {
    type: "FETCHING" | "PROCESSING";
    enabled: boolean;
  };
  dialog?: {
    enabled: boolean;
    title: string;
    message: string | JSX.Element;
    type: {
      info?: boolean;
      error?: boolean;
      alert?: boolean;
      fatal?: boolean;
      maintenance?: boolean;
      logout?: boolean;
    };
  };
}

export type ActionType = {
  type:
    | "LOADING"
    | "NOTIFICATIONS"
    | "MESSAGINGS_VISIBLE"
    | "MATCHINGS_VISIBLE"
    | "LIKES_VISIBLE"
    | "DIALOG"
    | "OLD";
  header?: State["header"];
  bottomNavi?: State["bottomNavi"];
  notificationsMap?: State["notificationsMap"];
  messagingsVisible?: State["messagingsVisible"];
  matchingsVisible?: State["matchingsVisible"];
  likesVisible?: State["likesVisible"];
  loading?: State["loading"];
  dialog?: State["dialog"];
};

// useReducerでreducer関数と初期値をセット
export const reducer = (oldState: State | any, action: ActionType | any) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...oldState,
        loading: {
          type: action.loading.type,
          enabled: action.loading.enabled,
        },
      };
    case "NOTIFICATIONS":
      return {
        ...oldState,
        notifications: action.notifications,
      };
    case "MESSAGINGS_VISIBLE":
      return {
        ...oldState,
        messagingsVisible: action.messagingsVisible,
      };
    case "MATCHINGS_VISIBLE":
      return {
        ...oldState,
        matchingsVisible: action.matchingsVisible,
      };
    case "LIKES_VISIBLE":
      return {
        ...oldState,
        likesVisible: action.likesVisible,
      };
    case "DIALOG":
      return {
        ...oldState,
        dialog: action.dialog,
      };
    case "OLD":
      return {
        ...oldState,
        ...action,
      };
    default:
      return oldState;
  }
};

export function enableLoading(
  dispatchLayout: React.Dispatch<ActionType>,
  loadingType: State["loading"]["type"]
) {
  dispatchLayout({
    type: "LOADING",
    loading: {
      type: loadingType,
      enabled: true,
    },
  });
}

export function disableLoading(dispatchLayout: React.Dispatch<ActionType>) {
  dispatchLayout({
    type: "LOADING",
    loading: {
      type: "FETCHING",
      enabled: false,
    },
  });
}

export function setNotifications(
  dispatchLayout: React.Dispatch<ActionType>,
  notificationsMap: State["notificationsMap"]
) {
  dispatchLayout({
    type: "NOTIFICATIONS",
    notificationsMap: notificationsMap,
  });
}

export function setMessagingsVisible(
  dispatchLayout: React.Dispatch<ActionType>,
  messagingsVisible: State["messagingsVisible"]
) {
  dispatchLayout({
    type: "MESSAGINGS_VISIBLE",
    messagingsVisible: messagingsVisible,
  });
}

export function setMatchingsVisible(
  dispatchLayout: React.Dispatch<ActionType>,
  matchingsVisible: State["matchingsVisible"]
) {
  dispatchLayout({
    type: "MATCHINGS_VISIBLE",
    matchingsVisible: matchingsVisible,
  });
}

export function setLikesVisible(
  dispatchLayout: React.Dispatch<ActionType>,
  likesVisible: State["likesVisible"]
) {
  dispatchLayout({
    type: "LIKES_VISIBLE",
    likesVisible: likesVisible,
  });
}
