import React from "react";
import { Notification, NotificationType } from "src/openapi";
import { ActionType, reducer, State } from "./Reducer";

const initialStateLayout: State = {
  header: true,
  bottomNavi: true,
  notificationsMap: new Map<NotificationType, Notification>(),
  messagingsVisible: false,
  matchingsVisible: false,
  likesVisible: false,
  dialog: {
    enabled: false,
    title: "",
    message: "",
    type: {
      info: false,
      error: false,
      fatal: false,
      maintenance: false,
    },
  },
  loading: {
    enabled: false,
    type: "FETCHING",
  },
};

type ContextType = {
  stateLayout: State;
  dispatchLayout: React.Dispatch<ActionType>;
};

const LayoutContext = React.createContext({} as ContextType);
function LayoutContextProvider(props) {
  const [stateLayout, dispatchLayout] = React.useReducer(
    reducer,
    initialStateLayout
  );
  const value = { stateLayout, dispatchLayout };

  return (
    <LayoutContext.Provider value={value}>
      {props.children}
    </LayoutContext.Provider>
  );
}

export { LayoutContext, LayoutContextProvider };
