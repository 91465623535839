import React, { useEffect, useMemo } from "react";
import { VersionCheck } from "src/action/Version";
import { LayoutContext } from "src/contexts/layout/LayoutContext";
import { useHistory } from "react-router-dom";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

export default function Components(props: Props) {
  const history = useHistory();
  const { stateLayout, dispatchLayout } = React.useContext(LayoutContext);

  useEffect(() => {
    VersionCheck(history, dispatchLayout);
  }, []);

  const Memo = useMemo(() => {
    return <React.Fragment>{props.children}</React.Fragment>;
  }, []);

  return <React.Fragment>{Memo}</React.Fragment>;
}
