// 与えた文字列が複数の正規表現に対してそのいずれかにマッチするかどうかを返す
const MatchingInRegexes = (str: string, regexList: string[]) => {
  let match = false;
  for (var i in regexList) {
    match = new RegExp(regexList[i]).test(str);
    if (match) {
      break;
    }
  }
  return match;
};

export { MatchingInRegexes };
