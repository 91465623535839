import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { ReactNode, useEffect } from "react";

interface StyleProps {
  visible: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  balloon: {
    position: "relative",
    display: "inline-block",
    background: "#fff2cc",
    borderRadius: "10px",
    padding: "16px",
    width: "100%",
    textAlign: "left",
    transition: "opacity 5s",
    opacity: ({ visible }) => (visible ? 1 : 0),
    "&:before": {
      content: "''",
      border: "10px solid transparent",
      borderTop: "10px solid #fff2cc",
      position: "absolute",
      top: "100%",
      left: "20%",
      transform: "translateX(-50%)",
    },
  },
}));

interface Props {
  handleRemoveBalloon: () => void;
  children: ReactNode;
}

export default function Component(props: Props) {
  const [visible, setVisible] = React.useState(true);
  const styleProps: StyleProps = { visible: visible };
  const classes = useStyles(styleProps);

  useEffect(() => {
    let cleanedUp = false;
    if (!cleanedUp) {
      props.handleRemoveBalloon();
      setTimeout(() => {
        setVisible(false);
      }, 500);
    }
    const cleanup = () => {
      cleanedUp = true;
    };
    return cleanup;
  }, []);

  return <div className={classes.balloon}>{props.children}</div>;
}
