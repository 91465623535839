import React from "react";
import { ActionType, reducer, State } from "./Reducer";
import lodash from "lodash";

// Reducer関数の初期値
const initialState: State = {
  hiddenUsersMap: new Map<string, boolean>(),
  usersStateMap: new Map<string, {}>(),
};

export const newState = (): State => {
  return lodash.cloneDeep(initialState);
};
type ContextType = {
  stateUsersListItem: State;
  dispatchUsersListItem: React.Dispatch<ActionType>;
};

const UsersListItemContext = React.createContext({} as ContextType);
function UsersListItemContextProvider(props) {
  // useReducerでreducer関数と初期値をセット
  const [stateUsersListItem, dispatchUsersListItem] = React.useReducer(
    reducer,
    newState()
  );
  const value = { stateUsersListItem, dispatchUsersListItem };
  React.useEffect(() => {
    let cleanedUp = false;
    if (!cleanedUp) {
    }
    const cleanup = () => {
      dispatchUsersListItem({
        type: "RESET",
      });
    };
    return cleanup;
  }, []);
  return (
    <UsersListItemContext.Provider value={value}>
      {props.children}
    </UsersListItemContext.Provider>
  );
}

export { UsersListItemContext, UsersListItemContextProvider };
