import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React, { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LayoutContext } from "src/contexts/layout/LayoutContext";
import { Link } from "react-router-dom";
import { DeleteSession } from "src/functions/User";
import { AuthContext } from "src/contexts/AuthContext";

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    display: "block",
    position: "relative",
    margin: theme.spacing(1),
    width: "70%",
    [theme.breakpoints.up("sm")]: {
      width: 600,
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  titleIcon: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    // backgroundColor: blue[100],
    // color: blue[600],
  },
  dialogContent: {
    padding: theme.spacing(2),
    width: "100%",
    // background: theme.palette.background.default,
  },
  listItemIcon: {
    color: theme.palette.success.main,
  },
  listItemTextEnd: {
    textAlign: "right",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    // marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Component(props) {
  const classes = useStyles();
  const history = useHistory();
  const { stateLayout, dispatchLayout } = React.useContext(LayoutContext);
  const { stateAuth, dispatchAuth } = React.useContext(AuthContext);
  const dialog = stateLayout.dialog;
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");

  const theme = useTheme();
  const fullScreen: boolean = !useMediaQuery(theme.breakpoints.up("sm"));

  const handleClose = () => {
    const dialog = {
      enabled: false,
      title: "",
      message: "",
      type: null,
    };
    dispatchLayout({ type: "OLD", dialog });
  };

  const handleForward = async () => {
    props.handleClose();
  };
  const handleLogout = () => {
    DeleteSession(dispatchAuth);
    history.push("/");
    handleClose();
  };

  const forwardLogin = () => {
    DeleteSession(dispatchAuth);
    history.push("/login");
    handleClose();
  };

  const location = useLocation();
  const handleRelaod = () => {
    window.location.reload();
  };

  const [path, setPath] = React.useState(location.pathname);
  useEffect(() => {
    let cleanedUp = false;
    if (!cleanedUp) {
      if (path !== location.pathname) {
        handleClose();
      } else {
        setPath(location.pathname);
      }
    }
    const cleanup = () => {
      cleanedUp = true;
    };
    return cleanup;
  }, [history.location]);
  const ContentText = () => {
    let message = dialog.message;
    return (
      <DialogContentText id="alert-dialog-description" color="inherit">
        {/* <Typography variant="body1" color="inherit"> */}
        {message}
        <br />
        {/* </Typography> */}
      </DialogContentText>
    );
  };

  const ButtonClose = () => {
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClose}
        className={classes.button}
      >
        閉じる
      </Button>
    );
  };

  const ButtonLogout = () => {
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={handleLogout}
        className={classes.button}
      >
        トップ画面へ
      </Button>
    );
  };
  const ButtonForwadLogin = () => {
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={forwardLogin}
        className={classes.button}
      >
        ログイン画面へ
      </Button>
    );
  };
  const ButtonLinkLogin = (props) => {
    return <ButtonLink linkTo="/login" label="ログイン画面へ" />;
  };
  const ButtonLink = (props) => {
    return (
      <Button
        variant="outlined"
        color="primary"
        component={Link}
        to={props.linkTo}
        className={classes.button}
      >
        {props.label}
      </Button>
    );
  };

  const Memo = useMemo(() => {
    return (
      <React.Fragment>
        <div className={classes.wrap}>
          <Dialog
            maxWidth="sm"
            fullWidth
            open={true}
            // onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            {dialog.title && (
              <DialogTitle id="alert-dialog-slide-title">
                <Grid container alignItems="center" spacing={1}>
                  <InfoOutlinedIcon className={classes.titleIcon} />
                  {dialog.title}
                </Grid>
              </DialogTitle>
            )}

            <DialogContent
              className={classes.dialogContent}
              dividers={scroll === "paper"}
            >
              <ContentText />
            </DialogContent>
            <DialogActions>
              <div className={classes.buttons}>
                {dialog.type.maintenance && <ButtonLogout />}
                {dialog.type.logout && <ButtonLinkLogin />}
                {dialog.type.alert && <ButtonClose />}
                {dialog.type.error && <ButtonLogout />}
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }, [fullScreen]);

  return <React.Fragment>{Memo}</React.Fragment>;
}
