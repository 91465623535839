import React from "react";
import lodash from "lodash";
import { ActionType, State } from "./Reducer";

// Reducer関数の初期値
const initialState: State = {
  title: "",
  searchOptionDialogOpen: false,
  userMenuDialogOpen: false,
};
export const newState = (): State => {
  return lodash.cloneDeep(initialState);
};

//　contextの型を宣言
// stateはParentコンポーネントのステート
// dispatchはステートを変える関数（正確にはどの処理を行うか振り分けるためのもの）
type ContextType = {
  stateHeader: State;
  dispatchHeader: React.Dispatch<ActionType>;
};

const HeaderContext = React.createContext({} as ContextType);
// useReducerでreducer関数と初期値をセット
const reducer = (oldState, action) => ({ ...oldState, ...action });

// コンテキストプロバイダーコンポーネント
function HeaderContextProvider(props) {
  // useReducerでreducer関数と初期値をセット
  const [stateHeader, dispatchHeader] = React.useReducer(reducer, newState());
  const value = { stateHeader, dispatchHeader };

  React.useEffect(() => {
    let cleanedUp = false;
    if (!cleanedUp) {
      //
    }
    const cleanup = () => {
      dispatchHeader({
        type: "RESET",
      });
    };
    return cleanup;
  }, []);

  return (
    // コンテキストプロバイダーとしてuseReducerのstateとdispatchをコンテキストに設定
    <HeaderContext.Provider value={value}>
      {props.children}
    </HeaderContext.Provider>
  );
}

export { HeaderContext, HeaderContextProvider };
