import { newState } from "./UsersListItemContext";

export interface State {
  hiddenUsersMap: Map<string, boolean>;
  usersStateMap: Map<string, any>;
}

export type ActionType = {
  type: "HIDDEN_USERS_MAP" | "USERS_STATE_MAP" | "RESET";
  hiddenUsersMap?: State["hiddenUsersMap"];
  usersStateMap?: State["usersStateMap"];
};

export const reducer = (oldState: State, action: ActionType) => {
  switch (action.type) {
    case "HIDDEN_USERS_MAP":
      return { ...oldState, hiddenUsersMap: action.hiddenUsersMap };
    case "USERS_STATE_MAP":
      return { ...oldState, usersStateMap: action.usersStateMap };
    case "RESET":
      const state = newState();
      return state;
    default:
      return oldState;
  }
};

export function setHiddenUsersMap(
  dispatchUsersListItem: React.Dispatch<ActionType>,
  hiddenUsersMap: State["hiddenUsersMap"]
) {
  dispatchUsersListItem({
    type: "HIDDEN_USERS_MAP",
    hiddenUsersMap: hiddenUsersMap,
  });
}

export function setUsersStateMap(
  dispatchIdentification: React.Dispatch<ActionType>,
  usersStateMap: State["usersStateMap"]
) {
  dispatchIdentification({
    type: "USERS_STATE_MAP",
    usersStateMap: usersStateMap,
  });
}
