import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AdbRoundedIcon from "@material-ui/icons/AdbRounded";
import CreditCardRoundedIcon from "@material-ui/icons/CreditCardRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import BlockRoundedIcon from "@material-ui/icons/BlockRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import BusinessRoundedIcon from "@material-ui/icons/BusinessRounded";
import GavelRoundedIcon from "@material-ui/icons/GavelRounded";
import PolicyRoundedIcon from "@material-ui/icons/PolicyRounded";
import AccountBalanceRoundedIcon from "@material-ui/icons/AccountBalanceRounded";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "src/contexts/AuthContext";
import { LayoutContext } from "src/contexts/layout/LayoutContext";
import { IsDevelopment } from "src/functions/Environment";
import { isNotificationDisabled } from "src/functions/Notification";
import { NotificationType } from "src/openapi";
import { CheckLogin } from "src/functions/User";

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  logoGrid: {
    marginTop: theme.spacing(1),
  },
  logo: {
    height: "40px",
    width: "auto",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

const IconShoePrints = () => {
  return (
    <FontAwesomeIcon
      // color="white"
      icon={["fas", "shoe-prints"]}
      transform={{ rotate: 270 }}
      style={{ fontSize: 19 }}
    />
  );
};

const IconDebug = () => {
  return <AdbRoundedIcon />;
};

const ItemsNoLogin = [
  {
    label: "トップ",
    linkTo: "/",
    icon: <ExitToAppRoundedIcon />,
  },
  // {
  //   label: "ログイン",
  //   linkTo: "/login",
  //   icon: <LockOpenRoundedIcon />,
  // },
  {
    label: "料金案内",
    linkTo: "/plans",
    icon: <CreditCardRoundedIcon />,
  },
  { label: "ヘルプ", linkTo: "/help", icon: <HelpOutlineRoundedIcon /> },
];

const ItemsSub = [
  // {
  //   label: "利用規約",
  //   linkTo: "/notifications",
  //   // icon: <NotificationsRoundedIcon />,
  // },
];

const ItemsDebug = [
  { label: "デバッグトップ", linkTo: "/debug_top", icon: <IconDebug /> },
  {
    label: "ログアウト",
    linkTo: "/debug_logout",
    icon: <ExitToAppRoundedIcon />,
  },
];

type Anchor = "top" | "left" | "bottom" | "right";
export default function Components() {
  const classes = useStyles();
  const anchor = "left";
  const { stateLayout, dispatchLayout } = React.useContext(LayoutContext);
  const { notificationsMap } = stateLayout;
  const { stateAuth, dispatchAuth } = React.useContext(AuthContext);
  const [stateDrawer, setStateDrawer] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const isLogin = CheckLogin(stateAuth, dispatchAuth);

  const Items = [
    {
      label: "お知らせ",
      linkTo: "/informations",
      icon: (
        <Badge
          color="secondary"
          variant="dot"
          invisible={isNotificationDisabled(
            notificationsMap,
            NotificationType.Informations
          )}
        >
          <NotificationsRoundedIcon />
        </Badge>
      ),
    },
    {
      label: "あしあと",
      linkTo: "/visitors",
      icon: (
        <Badge
          color="secondary"
          variant="dot"
          invisible={isNotificationDisabled(
            notificationsMap,
            NotificationType.Visitors
          )}
        >
          <IconShoePrints />
        </Badge>
      ),
    },

    {
      label: "お気に入りリスト",
      linkTo: "/favorites",
      icon: <StarRoundedIcon />,
    },

    {
      label: "ブロックリスト",
      linkTo: "/blocks",
      icon: <BlockRoundedIcon />,
    },

    // { label: "メモ", linkTo: "/memo", icon: <AssignmentRoundedIcon /> },
    {
      label: "設定",
      linkTo: "/settings",
      icon: <SettingsRoundedIcon />,
    },
    { label: "ヘルプ", linkTo: "/help", icon: <HelpOutlineRoundedIcon /> },
    { label: "問い合わせ", linkTo: "/requests", icon: <EmailRoundedIcon /> },
    { label: "運営会社", linkTo: "/company", icon: <BusinessRoundedIcon /> },
    {
      label: "利用規約",
      linkTo: "/terms-of-service",
      icon: <GavelRoundedIcon />,
    },
    {
      label: "プライバシーポリシー",
      linkTo: "/privacy-policy",
      icon: <PolicyRoundedIcon />,
    },
    {
      label: "特定商取引法に基づく表示",
      linkTo: "/notation-law",
      icon: <AccountBalanceRoundedIcon />,
    },
  ];

  const Logo = () => {
    return (
      <React.Fragment>
        <Grid container justify="center" className={classes.logoGrid}>
          <img
            key="logo"
            src="/images/common/logo.png"
            alt="Patrona"
            className={classes.logo}
          />
        </Grid>
      </React.Fragment>
    );
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setStateDrawer({ ...stateDrawer, [anchor]: open });
    };

  const Memo = React.useMemo(() => {
    const ListItemContent = (props) => {
      const item = props.item;
      return (
        <ListItem
          button
          key={item.label}
          // divider
          component={Link}
          to={item.linkTo}
        >
          {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : ""}

          <ListItemText primary={item.label} />
        </ListItem>
      );
    };
    const Lists = (anchor: Anchor) => (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {isLogin
            ? Items.map((item, index) => (
                <ListItemContent key={item.label} item={item} />
              ))
            : ItemsNoLogin.map((item, index) => (
                <ListItemContent key={item.label} item={item} />
              ))}
        </List>
        <Divider color="primary" />
        {/* <List>
        {ItemsSub.map((item, index) => (
          <ListItemContent key={item.label} item={item} />
        ))}
      </List>
      <Divider /> */}
        {IsDevelopment && (
          <List>
            {ItemsDebug.map((item, index) => (
              <ListItemContent key={item.label} item={item} />
            ))}
          </List>
        )}
      </div>
    );

    return (
      <React.Fragment key={anchor}>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(anchor, true)}
        >
          <Badge
            color="secondary"
            variant="dot"
            invisible={
              isNotificationDisabled(
                notificationsMap,
                NotificationType.Informations
              ) &&
              isNotificationDisabled(
                notificationsMap,
                NotificationType.Visitors
              )
            }
          >
            <MenuIcon />
          </Badge>
        </IconButton>
        <SwipeableDrawer
          anchor={anchor}
          open={stateDrawer[anchor]}
          onClose={toggleDrawer(anchor, false)}
          onOpen={toggleDrawer(anchor, true)}
        >
          <div className={classes.toolbar}>
            <Logo />
          </div>
          <Divider />
          {Lists(anchor)}
        </SwipeableDrawer>
      </React.Fragment>
    );
  }, [stateAuth, stateLayout, stateDrawer, anchor]);
  return <React.Fragment>{Memo}</React.Fragment>;
}
