import React from "react";
import { UsersListItemContextProvider } from "./UsersListItemContext";

// ラップされた子要素全てが、children に props に入ってくる
export const PartsContextProvider = ({ children }) => {
  return (
    <UsersListItemContextProvider>
      {/*  */}
      {children}
      {/*  */}
    </UsersListItemContextProvider>
  );
};
