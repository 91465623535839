import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Fetching from "src/components/parts/loading/Fetching";
import Env from "src/consts/environment";
import { useTracking } from "src/googleAnalytics";

const WrapNeedLogin = lazy(() => import("./components/wrapper/WrapNeedLogin"));
const WrapNoLogin = lazy(() => import("./components/wrapper/WrapNoLogin"));
const Top = lazy(() => import("src/pages/Top"));
const SignUp = lazy(() => import("src/pages/SignUp"));
const SignUpFacebook = lazy(() => import("src/pages/SignUpFacebook"));
const Login = lazy(() => import("src/pages/Login"));
const ChangePassword = lazy(() => import("src/pages/ChangePassword"));
const Help = lazy(() => import("src/pages/Help"));
const PrivacyPolicy = lazy(() => import("src/pages/PrivacyPolicy"));
const TermsOfService = lazy(() => import("src/pages/TermsOfService"));
const Company = lazy(() => import("src/pages/Company"));
const NotationLaw = lazy(() => import("src/pages/NotationLaw"));
const Requests = lazy(() => import("src/pages/Requests"));
const Plans = lazy(() => import("src/pages/Plans"));
const Medias = lazy(() => import("src/pages/Medias"));
const Affiliate = lazy(() => import("src/pages/Affiliate"));

const RoutesNoLogin = [
  { path: "/", component: <Top /> },
  { path: "/signup", component: <SignUp /> },
  { path: "/signup-facebook", component: <SignUpFacebook /> },
  { path: "/login", component: <Login /> },
  { path: "/change-password", component: <ChangePassword /> },
  { path: "/plans", component: <Plans /> },
  { path: "/help", component: <Help /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/terms-of-service", component: <TermsOfService /> },
  { path: "/company", component: <Company /> },
  { path: "/notation-law", component: <NotationLaw /> },
  { path: "/requests/:id", component: <Requests /> },
  { path: "/requests", component: <Requests /> },
  { path: "/medias", component: <Medias /> },
  { path: "/affiliate", component: <Affiliate /> },
];

const Favorites = lazy(() => import("src/pages/Favorites"));
const Blocks = lazy(() => import("src/pages/Blocks"));
// const ChangePhoneNumber = lazy(() => import("src/pages/ChangePhoneNumber"));
const EmailSetting = lazy(() => import("src/pages/EmailSetting"));
const Verify = lazy(() => import("src/pages/Verify"));
const Contacts = lazy(() => import("src/pages/Contacts"));
const AgeCertification = lazy(() => import("src/pages/AgeCertification"));
const IncomeCertification = lazy(() => import("src/pages/IncomeCertification"));
const Informations = lazy(() => import("src/pages/Informations"));
const Likes = lazy(() => import("src/pages/Likes"));
const Messages = lazy(() => import("src/pages/Messages"));
const VideoCallRoom = lazy(() => import("src/pages/VideoCallRoom"));
const MyPage = lazy(() => import("src/pages/MyPage"));
const NotFound = lazy(() => import("src/pages/NotFound"));
const PaidMembership = lazy(() => import("src/pages/PaidMembership"));
//const PaymentMethod = lazy(() => import("src/pages/PaymentMethod"));
const PaymentPlans = lazy(() => import("src/pages/PaymentPlans"));
const PaymentComplete = lazy(() => import("src/pages/PaymentComplete"));
const PaymentChanged = lazy(() => import("src/pages/PaymentChanged"));
const Resignation = lazy(() => import("src/pages/Resignation"));
const Search = lazy(() => import("src/pages/Search"));
const Settings = lazy(() => import("src/pages/Settings"));
const Users = lazy(() => import("src/pages/Users"));
const Visitors = lazy(() => import("src/pages/Visitors"));
const PrivateMode = lazy(() => import("src/pages/PrivateMode"));
const BulletinBoards = lazy(() => import("src/pages/BulletinBoards"));
// const CampaignCode = lazy(() => import("src/pages/campaign_code/CampaignCode"));
const RoutesNeedLogin = [
  // { path: "/instantly_board", component: <InstantlyBoard /> },
  { path: "/search", component: <Search /> },
  { path: "/users/profile/:id", component: <Users /> },
  { path: "/informations", component: <Informations /> },
  { path: "/private-mode", component: <PrivateMode /> },
  { path: "/visitors", component: <Visitors /> },
  { path: "/likes", component: <Likes /> },
  { path: "/contacts", component: <Contacts /> },
  { path: "/messages/:id", component: <Messages /> },
  { path: "/video-call-room/:id", component: <VideoCallRoom /> },
  { path: "/favorites", component: <Favorites /> },
  { path: "/blocks", component: <Blocks /> },
  { path: "/mypage", component: <MyPage /> },
  { path: "/settings", component: <Settings /> },
  { path: "/age-certification", component: <AgeCertification /> },
  // { path: "/income-certification", component: <IncomeCertification /> },  // TODO:収入証明
  { path: "/paid-membership", component: <PaidMembership /> },
  //{ path: "/payment-method", component: <PaymentMethod /> },
  { path: "/payment/plans", component: <PaymentPlans /> },
  { path: "/payment/complete", component: <PaymentComplete /> },
  { path: "/payment/changed", component: <PaymentChanged /> },
  // { path: "/change-phone-number", component: <ChangePhoneNumber /> },
  { path: "/email-setting", component: <EmailSetting /> },
  { path: "/verify-email/:verificationCode", component: <Verify /> },
  // { path: "/campaign-code", component: <CampaignCode /> },
  { path: "/resignation", component: <Resignation /> },
  { path: "/bulletin-boards", component: <BulletinBoards /> },
];

const DebugTop = lazy(() => import("src/pages/debug/DebugTop"));
const DebugUserList = lazy(() => import("src/components/debug/UsersList"));
const DebugLogout = lazy(() => import("src/pages/debug/Logout"));
const SandBox = lazy(() => import("src/components/SandBox"));

const RoutesDebugNoLogin = [
  { path: "/debug_top", component: <DebugTop /> },
  { path: "/debug/user_list", component: <DebugUserList /> },
  { path: "/debug/sandbox", component: <SandBox /> },
];

const RoutesDebugNeedLogin = [
  { path: "/debug_logout", component: <DebugLogout /> },
];

export default function Component() {
  const trackingIDs = [Env.GOOGLE_ANALITICS_TRACKING_ID];
  if (Env.GOOGLE_ANALITICS_TRACKING_ID_ADS) {
    trackingIDs.push(Env.GOOGLE_ANALITICS_TRACKING_ID_ADS);
  }
  useTracking(trackingIDs);
  return (
    // <BrowserRouter>
    <Suspense fallback={<Fetching />}>
      <Switch>
        {/* ログインなし */}
        {RoutesNoLogin.map((route) => (
          <Route key={route.path} exact path={route.path}>
            <WrapNoLogin>{route.component}</WrapNoLogin>
          </Route>
        ))}

        {/* ログイン必要 */}
        {RoutesNeedLogin.map((route) => (
          <Route key={route.path} exact path={route.path}>
            <WrapNeedLogin>{route.component}</WrapNeedLogin>
          </Route>
        ))}

        {/* デバッグ */}
        {/* ログインなし */}
        {Env.MODE == "development" &&
          RoutesDebugNoLogin.map((route) => (
            <Route key={route.path} exact path={route.path}>
              {route.component}
            </Route>
          ))}
        {/* ログイン必要 */}
        {Env.MODE == "development" &&
          RoutesDebugNeedLogin.map((route) => (
            <Route key={route.path} exact path={route.path}>
              <WrapNeedLogin>{route.component}</WrapNeedLogin>
            </Route>
          ))}

        {/* URLヒットしないときはNot Found画面を表示する */}
        <Route key="notfound">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
    // </BrowserRouter>
  );
}
