import React from "react";

const AuthContext = React.createContext(null);
// useReducerでreducer関数と初期値をセット
const reducer = (oldState, action) => ({ ...oldState, ...action });

// Reducer関数の初期値
const initialStateAuth = {
  userId: "",
  sessionToken: "",
  tmpLoginFlag: false,
  user: null,
  likesSendCount: 0,
  idempotencyKey: "",
  subscriptions: [],
  paidMember: false,
  fetchedSubscriptions: false,
  hasModifiedSubscription: false,
};

// コンテキストプロバイダーコンポーネント
function AuthContextProvider(props) {
  // useReducerでreducer関数と初期値をセット
  const [stateAuth, dispatchAuth] = React.useReducer(reducer, initialStateAuth);
  const value = { stateAuth, dispatchAuth };

  return (
    // コンテキストプロバイダーとしてuseReducerのstateとdispatchをコンテキストに設定
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
// コンテキストコンシュマーの作成
const AuthContextConsumer: React.Consumer<any> = AuthContext.Consumer;

export {
  initialStateAuth,
  AuthContext,
  AuthContextProvider,
  AuthContextConsumer,
};
