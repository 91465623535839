import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
        * {
          font-family: 
              'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'Helvetica Neue', 'Arial', 'Meiryo', 'Kosugi', sans-serif;
            // CSS you want global. 
            overflow-wrap: break-word;
        }
        html {
          height: 100vh;
        }
        @font-face {
            font-family: 'NotoSansCJKjp';
            font-style: normal;
            font-weight: 700;
            src:
              url('font/NotoSansCJKjp.woff') format('woff');
          }
        `;
