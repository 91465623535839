import { disableLoading } from "src/contexts/layout/Reducer";

export interface Content {
  type: "alert" | "error";
  title?: string;
  message: string | JSX.Element;
}
export const HandleHttpErrorResponse = (
  error,
  dispatchLayout: React.Dispatch<any>,
  content?: Content
) => {
  if (error && error.response) {
    const errorResponse = error.response;
    switch (true) {
      case CheckLoginError(dispatchLayout, errorResponse.data):
        break;
      case CheckMaintenance(dispatchLayout, errorResponse.data):
        break;
      default:
        SetError(dispatchLayout, content);
        break;
    }
  } else {
    SetError(dispatchLayout, content);
  }

  if (error) {
    console.log(error);
    if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
  }
  disableLoading(dispatchLayout);
  return;
};

export const CheckMaintenance = (
  dispatchLayout: React.Dispatch<any>,
  errorData
) => {
  if (errorData.maintenance) {
    // const dialog = {
    //   enabled: false,
    //   title: "",
    //   message: "",
    //   type: {
    //     info: false,
    //     error: false,
    //     fatal: false,
    //     maintenance: true,
    //   },
    // };
    // history.push("/likes");

    const dialog = {
      enabled: true,
      title: "メンテナンス",
      message: "只今メンテナンス中です",
      type: {
        maintenance: true,
      },
    };
    dispatchLayout({ type: "OLD", dialog });
    return true;
  }
  return false;
};

export const CheckLoginError = (
  dispatchLayout: React.Dispatch<any>,
  errorData
) => {
  if (errorData.logout) {
    const dialog = {
      enabled: true,
      title: "ログインしてください",
      message: "ログアウトしました。再度ログインしてください。",
      type: {
        logout: true,
      },
    };
    dispatchLayout({ type: "OLD", dialog });
    return true;
  }
  return false;
};

export const SetError = (
  dispatchLayout: React.Dispatch<any>,
  content?: Content
) => {
  if (!content) {
    content = {
      type: "error",
      title: "エラーが発生しました",
      message: "TOP画面へ戻ります",
    };
  }
  const dialog = {
    enabled: true,
    title: content.title ? content.title : "エラーが発生しました",
    message: content.message,
    type: {
      alert: content.type === "alert",
      error: content.type === "error",
    },
  };
  dispatchLayout({ type: "OLD", dialog });
  return true;
};
