import { grey, orange, green, red, purple } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = () =>
  createTheme({
    typography: {
      fontFamily: '"Kosugi", san-serif',
    },
    palette: {
      primary: {
        // main: material[800],
        main: "#00a7d9",
        light: "#2fcfff",
        dark: "#006684",
        contrastText: "#fff",
      },
      secondary: {
        main: "#f3981d",
        // main: "#e8945f",
        light: "#ffc953",
        dark: "#bb6a00",
        contrastText: "#2b2b2b",
      },
      error: {
        main: red.A400,
      },
      background: {
        default: grey[200],
        paper: "#fff",
      },
      success: {
        main: green.A400,
      },
    },
    overrides: {
      MuiBottomNavigationAction: {
        root: {
          maxWidth: "100%",
          minWidth: "auto",
        },
        label: {
          fontSize: ".6rem",
          "&$selected": {
            fontSize: ".625rem",
          },
        },
      },
      MuiImageListItem: {
        root: {
          maxWidth: "110px",
          minWidth: "110px",
        },
      },
      MuiListSubheader: {
        gutters: {
          padding: 0,
          paddingLeft: "8px",
          paddingRight: "8px",
        },
      },
      MuiListItem: {
        root: {
          paddingTop: "4px",
          paddingBottom: "4px",
        },
      },
    },
  });

export default theme;
