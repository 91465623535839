import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import React from "react";
import { HeaderContext } from "src/contexts/header/HeaderContext";
import { setUserMenuDialogOpen } from "src/contexts/header/Reducer";

// スタイル
const useStyles = makeStyles((theme) => ({}));

interface Props {}

export default function Component(props: Props) {
  const classes = useStyles();
  const { stateHeader, dispatchHeader } = React.useContext(HeaderContext);

  const handleMenuDialog = () => {
    const dialog = !stateHeader.userMenuDialogOpen;
    setUserMenuDialogOpen(dispatchHeader, dialog);
  };
  return (
    <>
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleMenuDialog}
        aria-label="menu"
      >
        <MoreVertRoundedIcon />
      </IconButton>
    </>
  );
}
