import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Components() {
  const location = useLocation();

  useEffect(() => {
    let cleanedUp = false;
    if (!cleanedUp) {
      window.scrollTo(0, 0);
    }
    const cleanup = () => {
      cleanedUp = true;
    };
    return cleanup;
  }, [location]);

  return <></>;
}
