import gender from "src/consts/json/gender.json";
import Env from "./environment";

export namespace Consts {
  export const SERVICE_NAME = "Patrona";
  export const SERVICE_NAME_FURIGANA = "パトローナ";
  export const COMPANY_NAME = "株式会社ジーク";
  export const COMPANY_NAME_EN = "ZEAK Corporation";
  export const ACCEPTANCE_NUMBER = {
    インターネット異性紹介事業: "30210067-000",
    商標: "第6521794号",
    電気通信事業: "A-03-19075",
  };
  export const COMPANY_LOCATION =
    "〒104-0061 東京都中央区銀座1-12-4N&E BLD.7階";

  export const COMPANY_CONTACT = "050-1807-6416";

  // 秒数定義
  export const MINUTE_IN_SECONDS = 60; // 1分の秒数
  export const HOUR_IN_SECONDS = 60 * MINUTE_IN_SECONDS; // 1時間の秒数
  export const DAY_IN_SECONDS = 24 * HOUR_IN_SECONDS; // 1日(24時間)の秒数
  export const WEEK_IN_SECONDS = 7 * DAY_IN_SECONDS; // 7日間の秒数
  export const MONTH_IN_SECONDS = 30 * DAY_IN_SECONDS; // 30日の秒数
  export const YEAR_IN_SECONDS = 365 * DAY_IN_SECONDS; // 1年の秒数

  export const SIGNUP_COOLTIME_DAYS = 7;
  export const GENDER = {
    MALE: gender.data.findIndex((item) => item.value === "男性"),
    FEMALE: gender.data.findIndex((item) => item.value === "女性"),
  };
  export const USER_PROFILE = {
    NAME_MAX_LENGTH: 12,
    // 写真ホスト
    PHOTO_BASE_URL: Env.CLOUD_STORAGE_URL,
    // 写真最大数
    SUB_PHOTO_NUM: 6,
    // マッチングフォト最大数
    MATCHING_PHOTO_NUM: 2,
    // エリア最大数
    AREA_NUM: 3,
    // 自己紹介の文字数
    INTRODUCTION_MAX_LENGTH: 1000,
    // 自己紹介の文字数
    PRIVATE_INTRODUCTION_MAX_LENGTH: 200,
  };
  export const EMAIL_ADDRESS_MAX_LENGTH = 254;
  export const LIKES_DAILY_LIMIT = 50;
  export const MAX_WIDTH = 600;
  export const CS_REQUEST_CATEGORY_AFFILIATE = 4;

  export const USERS_INDEXES: string[] = [
    "Users",
    "Users_createdAt",
    "Users_accessedAt",
  ];

  export const MAX_BULLETIN_BOARD_COUNT = 10;
  export const RECAPCHA_CONTAINER_ID = "recaptcha-container";

  export const OWNAFFILIATE_FIRST_PAYMENT_SENARIO_ID = "jWsJPrrU";
  export const OWNAFFILIATE_AGE_CERTIFICATE_SENARIO_ID = "1oFBj5fG";
  export const OWNAFFILIATE_MALE_SIGNUP_SENARIO_ID = "v54pc8i5";
  export const OWNAFFILIATE_FEMALE_SIGNUP_SENARIO_ID = "uo5OQcoF";
}
