import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import React, { useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Consts } from "src/consts/consts";
import { HeaderContext } from "src/contexts/header/HeaderContext";
import { setTitle } from "src/contexts/header/Reducer";
import AgeCertification from "./AgeCertification";
import Contacts from "./Contacts";
import Drawer from "./Drawer";
import Likes from "./Likes";
import MyPage from "./MyPage";
import Search from "./Search";
import Settings from "./Settings";
import Top from "./Top";
import Users from "./Users";
import Visitors from "./Visitors";
import BulletinBoards from "./BulletinBoards";
import HideOnScroll from "src/components/parts/scroll/HideOnScroll";

// スタイル
const useStyles = makeStyles((theme) => ({
  appBar: {
    // position: "relative",
    // zIndex: theme.zIndex.drawer + 10,
    [theme.breakpoints.up("sm")]: {
      width: "600px",
    },
    left: "auto",
    right: "auto",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

export default function Component(props: Props) {
  const classes = useStyles();
  const { stateHeader, dispatchHeader } = React.useContext(HeaderContext);
  let title: string = stateHeader.title;

  const IconShoePrints = () => {
    return (
      <IconButton component={Link} to={`/visitors`}>
        <FontAwesomeIcon
          color="white"
          icon={["fas", "shoe-prints"]}
          transform={{ rotate: 270 }}
        />
      </IconButton>
    );
  };

  const location = useLocation();

  const history = useHistory();
  const handleBack = () => {
    // setOpen(false);
    history.goBack();
  };

  const IconBack = () => {
    return (
      <IconButton
        onClick={handleBack}
        color="inherit"
        className={classes.menuButton}
      >
        <ArrowBackRoundedIcon />
      </IconButton>
    );
  };

  const Memo = useMemo(() => {
    const path = location.pathname;
    if (path === "/") {
      return (
        <React.Fragment>
          {/* <Top /> */}
          {props.children}
        </React.Fragment>
      );
    }
    let Content: JSX.Element = <></>;
    let Button: JSX.Element = <Drawer />;

    switch (path) {
      case "/plans":
        title = "料金案内";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/search":
        title = "探す";
        Content = (
          <>
            <Search />
          </>
        );
        break;
      case "/informations":
        title = "お知らせ";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/visitors":
        title = "あしあと";
        Content = (
          <>
            <Visitors />
          </>
        );
        Button = <IconBack />;
        break;
      case "/likes":
        title = "いいね";
        Content = (
          <>
            <Likes />
          </>
        );
        break;
      case "/contacts":
        title = "やりとり";
        Content = (
          <>
            <Contacts />
          </>
        );
        break;
      case "/bulletin-boards":
        title = "掲示板";
        Content = (
          <>
            <BulletinBoards />
          </>
        );
        break;
      case "/mypage":
        title = "マイページ";
        Content = (
          <>
            <MyPage />
          </>
        );
        break;
      case "/settings":
        title = "設定";
        Content = (
          <>
            <Settings />
          </>
        );
        Button = <IconBack />;
        break;
      case "/change-phone-number":
        title = "電話番号の変更";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/change-password":
        title = "パスワード変更";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/email-setting":
        title = "メールアドレス設定";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/favorites":
        title = "お気に入りリスト";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/blocks":
        title = "ブロックリスト";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/age-certification":
        Content = (
          <>
            <AgeCertification />
          </>
        );
        Button = <IconBack />;
        break;
      case "/income-certification":
        title = "所得証明";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/paid-membership":
        title = "会員ステータス";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/payment/plans":
        title = "有料会員登録";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/payment/complete":
        title = "決済完了";
        Content = <></>;
        break;
      case "/payment-method":
        title = "クレジットカード情報の変更";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/payment/changed":
        title = "クレジットカード情報変更完了";
        Content = <></>;
        break;
      case "/resignation":
        title = "退会手続き";
        Content = <></>;
        break;
      case "/private-mode":
        title = "プライベートモード設定";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/privacy-policy":
        title = "プライバシーポリシー";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/terms-of-service":
        title = "利用規約";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/company":
        title = "会社概要";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/notation-law":
        title = "特定商取引法に基づく表示";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/help":
        title = "ヘルプ";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/requests":
        title = "お問い合わせ";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/campaign-code":
        title = "キャンペーンコード入力";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/medias":
        title = "メディア掲載情報";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/affiliate":
        title = "アフィリエイト";
        Content = <></>;
        Button = <IconBack />;
        break;
      case "/debug_logout":
        title = "ログアウト";
        Content = <></>;
        Button = <IconBack />;
        break;
      default:
        break;
    }

    // console.log(/^users\/profile\/(.*)$/.test(path) ? "true" : "false");
    // 正規表現でチェック
    switch (true) {
      case /^\/users\/(.*)$/.test(path):
        Content = (
          <>
            <Users />
          </>
        );
        Button = <IconBack />;
        break;
      case /^\/verify-email\/(.*)$/.test(path):
        Content = (
          <>
            <Users />
          </>
        );
        title = "メールアドレス確認完了";
        Content = <></>;
        Button = <IconBack />;
        break;
    }

    document.title = title
      ? `${title} | ${Consts.SERVICE_NAME}`
      : Consts.SERVICE_NAME;

    if (title !== stateHeader.title) {
      setTitle(dispatchHeader, title);
    }
    return (
      <React.Fragment>
        <HideOnScroll {...props}>
          <AppBar color="primary" className={classes.appBar}>
            <Toolbar>
              {Button}
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
              {Content}
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <Toolbar />
        {props.children}
      </React.Fragment>
    );
  }, [props, location, title]);
  return <React.Fragment>{Memo}</React.Fragment>;
}
