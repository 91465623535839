export { addDevelopMetaTag };

function addDevelopMetaTag() {
  noIndexTag();
}

function noIndexTag() {
  let tag = document.createElement("meta");
  tag.name = "robots";
  tag.content = "noindex";
  document.head.appendChild(tag);
}
