import { Consts } from "src/consts/consts";
import { useCookies } from "react-cookie";

const MessagePermitted = (stateAuth) => {
  let permitted = false;
  const user = stateAuth.user;
  const certificated = user.AgeCertification.Certificated;
  const gender = user.Gender;
  switch (gender) {
    case Consts.GENDER.MALE:
      permitted = stateAuth.paidMember;
      break;
    case Consts.GENDER.FEMALE:
      permitted = certificated;
      break;
  }
  return permitted;
};

const TargetPermitted = (stateVisit, user) => {
  let permitted = false;
  if (user.Gender == Consts.GENDER.FEMALE) {
    permitted = user.AgeCertification.Certificated;
  } else if (user.Gender == Consts.GENDER.MALE) {
    permitted = stateVisit.isPaidMember;
  }
  return permitted;
};

/**
 * ログイン状態にする
 *   sessionクッキーはサーバー側で作成されるため、tmpLoginFlagの設定のみ
 * @param dispatchAuth
 */
const CreateSession = (dispatchAuth) => {
  dispatchAuth({ tmpLoginFlag: true });
};

/**
 * クライアント側でログイン状態かを判定する
 * @param stateAuth
 * @param dispatchAuth
 * @returns
 */
const CheckLogin = (stateAuth, dispatchAuth) => {
  const [cookies, setCookie, removeCookie] = useCookies(["session"]);
  // サーバー側で作成されたCookieについては、作成前に読み込まれたコンポーネントからは作成、削除のステータスが反映されない
  // ex)
  //    トップ->ログイン画面からログインした際、ログイン後に読み込まれるNeedLoginコンポーネントからはsessionクッキーが読み取れるが、ログイン前に読み込まれているWrapLayoutコンポーネントからはsessionクッキーが読み取れない(リロードするとWrapLayoutからも読み取れるようになる)
  //    退会処理時にクライアントからsession クッキーを削除しても、それ以前に読み込まれているNeedLoginコンポーネントからはCookieが削除されていることが反映されない。(=引続きsessionクッキーが取得できる。リロードすると反映され取得できなくなる)
  // このようにタイミングによってクッキーだけではリロードするまでログイン状況が反映できない場合があるため tmpLoginFlagも使用してログイン判定を行う
  const existCookie = cookies.session !== undefined;
  const isLogin = stateAuth.tmpLoginFlag || existCookie;
  if (stateAuth.tmpLoginFlag === true && existCookie) {
    const tmpLoginFlag = false;
    dispatchAuth({ tmpLoginFlag });
  }
  return isLogin;
};

/**
 * クライアント側をログアウト状態にする
 * @param dispatchAuth
 */
const DeleteSession = (dispatchAuth) => {
  const [, , removeCookie] = useCookies(["session"]);
  dispatchAuth({ tmpLoginFlag: false });
  removeCookie("session", { path: "/" });
};

export {
  MessagePermitted,
  TargetPermitted,
  CheckLogin,
  CreateSession,
  DeleteSession,
};
