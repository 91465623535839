import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

// スタイル
const useStyles = makeStyles((theme) => ({}));

interface Props {}

export default function Component(props: Props) {
  const classes = useStyles();
  return (
    <>
      <IconButton color="inherit">
        {/* <TuneRoundedIcon /> */}
        {/* <SettingsRoundedIcon /> */}
      </IconButton>
    </>
  );
}
